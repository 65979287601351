.utility {
  .search-form {
    margin-left: 40px;
    opacity: 0.7;
    border: none;
    border-bottom: 2px solid #fff;

    .search-field {
      background-color: transparent;
      line-height: 14px;
      font-size: 13px;
      font-weight: 700;
      color: #fff;
      border: none;
      border-radius: 0;
      -webkit-font-smoothing: antialiased;
      // margin-right: 23px;

      &::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #fff;
      }

      &:active {
        background-color: transparent;
      }
    }

    .search-submit {
      opacity: 1;
      padding: 8px 0px;
      cursor: pointer;
      color: #0054a6;
      display: inline-block;

      border: 2px solid #0054a6;
      border-radius: 6.25rem;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 0;
      // padding: 0.5rem 1.5rem;
      transition: all 0.2s ease-in-out;
      // background-color: #fdc82f;
      // border-color: #fdc82f;
      background-color: transparent;
      border-color: transparent;
    }
  }
  .search-icon {
    background-image: url("../images/icons/magnifying-glass.svg");
    width: 16px;
    height: 16px;
  }
}

.resources-search {
  .search-form {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0 40px 32px 0;
    display: inline-flex;
    flex-wrap: nowrap;
    // gap: 23px;
    justify-content: space-between;

    .search-field {
      // line-height: 14px;
      font-size: 13px;
      font-weight: 700;
      opacity: 0.7;

      border: transparent;

      @include media-breakpoint-up(xlg) {
        margin-right: 23px;
      }

      &::placeholder {
        font-size: 13px;
        font-weight: 700;
      }
    }

    .search-submit {
      opacity: 1;
      padding: 8px 12px;
      cursor: pointer;
      color: #0054a6;
      display: inline-block;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 0;
      // padding: 0.5rem 1.5rem;
      transition: all 0.2s ease-in-out;
      // background-color: #fdc82f;
      // border-color: #fdc82f;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background: transparent;
      }
    }
  }
  .search-icon {
    background-image: url("../images/icons/magnifying-glass-dark.svg");
    width: 16px;
    height: 16px;
    opacity: 0.7;
  }
}

.search {
  .header-image {
    display: none;
  }

  .content {
    padding-bottom: 150px;
  }

  .page-header {
    background: #6a3883 url(../images/icons/fill-1x.svg) left 30% no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 350px;
    padding: 44px 0;
    margin-bottom: 4rem;
    color: #fff;

    @include min($md) {
      min-height: 400px;
      padding: 48px 0;
    }
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 60px;
    font-weight: 800;
    margin: 0;
  }

  article {
    margin-bottom: 45px;
    // border-bottom: 1px solid #dcdcdc;

    @include min($sm) {
      margin-bottom: 55px;
    }

    a {
      font-size: 1.25rem;

      @include min($sm) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 0.9rem;

      @include min($sm) {
        font-size: 1rem;
      }
    }

    .post-type {
      font-weight: bold;
      text-transform: capitalize;
      font-size: 0.8rem;
    }
  }

  nav {
    // padding-top: 30px;
    // max-width: 1170px;
    // margin: 0 auto;
    // padding-left: 2rem;
    // padding-right: 2rem;

    .nav-links {
      display: flex;
      justify-content: space-between;
      // gap: 30px;
    }
  }

  .alert {
    margin-bottom: 100px;
  }
}

.search-pagination {
  padding-top: 30px;
  .pagination {
    justify-content: center;
    gap: 10px;
  }
}
