.block-big-section {
  padding: 4rem 2rem 0;

  @include media-breakpoint-down(md) {
    padding: 2rem 1rem;
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 2.375rem;
    font-weight: $weight-bold;
    color: $color-blue;
  }

  p {
    margin-bottom: 0;
    font-size: 1.25rem;
    color: $color-gray;
  }
}
