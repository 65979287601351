.rss-feed {
	background-color: $color-paleblue;
	padding: 4rem 0;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		padding: 2rem 0;
	}

	> * {
		position: relative;
		z-index: 100;
	}

	.bg-icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateY(-25%);
		z-index: 1;

		.svg.icon {
			min-width: 100%;
			min-height: 200%;

			svg {
				position: absolute;
				top: 0;
				left: 0;
				// min-width: 100%;
				min-height: 100%;
			}
		}
	}

	h2 {
		color: $color-blue;
		font-weight: $weight-bold;
		margin: 0 0 4rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}

	article.feed-item {
		margin-bottom: 1rem;

		a {
			&,
			&:link,
			&:visited {
				position: relative;
				background-color: $color-white;
				border-left: 1px solid rgba($color-black, 0.1);
				border-right: 1px solid rgba($color-black, 0.1);
				border-bottom: 1px solid rgba($color-black, 0.1);
				padding: 1.5rem 2rem;
				height: 100%;
				border-radius: 0.1875rem;
				display: block;
				overflow: hidden;
				color: $color-black;
				box-shadow: 0 0 0 0 rgba($color-black, 0);

				@include media-breakpoint-down(xs) {
					margin: 0 0 1rem;
				}

				&:after {
					content: '';
					position: absolute;
					bottom: 1rem;
					right: -4rem;
					display: block;
					width: 3rem;
					height: 3rem;
					background: $color-yellow url('../images/icons/arrow-right-white.svg') center no-repeat;
					background-size: 1rem;
					border-radius: 50%;
					@include transition();
				}
			}

			&:hover {
				text-decoration: none;
				box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
				transform: scale(1.025);

				&:after {
					right: 1rem;
				}
			}

			&:focus,
			&:active {
				text-decoration: none;
			}
		}

		h3.item-title {
			margin-bottom: 0.5rem;
		}

		p.item-meta {
			margin-bottom: 0.5rem;
			font-size: 0.875rem;
			font-weight: $weight-extrabold;
		}

		.item-description {
			p {
				&:last-child {}
			}
		}

		&:nth-child(5n+1) {
			> a,
			> a:link,
			> a:visited {
				border-top: 0.5rem solid $color-blue;
			}
		}

		&:nth-child(5n+2) {
			> a,
			> a:link,
			> a:visited {
				border-top: 0.5rem solid $color-lightblue;
			}
		}

		&:nth-child(5n+3) {
			> a,
			> a:link,
			> a:visited {
				border-top: 0.5rem solid $color-yellow;
			}
		}

		&:nth-child(5n+4) {
			> a,
			> a:link,
			> a:visited {
				border-top: 0.5rem solid $color-lightgreen;
			}
		}

		&:nth-child(5n+5) {
			> a,
			> a:link,
			> a:visited {
				border-top: 0.5rem solid $color-green;
			}
		}
	}
}
