.archive-event {
  .intro {
    padding: 4rem 0;

    @include media-breakpoint-down(md) {
      padding: 2rem 0;
    }

    .why-attend-acs,
    .need-to-know {
      .svg.icon {
        display: block;
        float: left;
        width: 3.25rem;
        height: 3.25rem;
        color: transparent;
        fill: transparent;
        stroke: $color-black;
        stroke-width: 0.125rem;

        .color {
          stroke: $color-purple;
        }

        @include media-breakpoint-down(sm) {
          float: none;
          margin-left: auto;
          margin-right: auto;
        }
      }

      h3 {
        font-size: 1.25rem;
        font-weight: $weight-bold;
        text-align: center;
        margin: 1rem 0;

        @include media-breakpoint-up(md) {
          padding: 1.25rem 0;
          margin: 0 0 0 4rem;
          text-align: left;
        }
      }

      p {
        font-size: 1.125rem;
      }

      @include media-breakpoint-up(lg) {
        position: relative;

        .svg.icon {
          position: absolute;
          top: 0;
          left: 1rem;
        }
      }
    }

    .need-to-know {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .event-map {
    position: relative;
    height: 0;
    padding-bottom: 31.185%;

    @include media-breakpoint-down(sm) {
      padding-bottom: 100%;
    }

    #map,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .browse-events {
    background-color: $color-paleblue;
    background-size: cover;
    padding: 4rem 0;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
      background-size: auto 100%;
    }

    > * {
      position: relative;
      z-index: 100;
    }

    .bg-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateY(-25%);
      z-index: 1;

      .svg.icon {
        min-width: 100%;
        min-height: 200%;
      }
    }

    h2 {
      color: $color-blue;
      font-size: 2.375rem;
      font-weight: $weight-bold;
      margin: 0 0 4rem;

      @include media-breakpoint-down(sm) {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }
    }

    .single-event {
      padding-bottom: 2rem;
      border-bottom: 1px solid rgba($color-blue, 0.15);
      margin-bottom: 2rem;

      .photo {
        @include media-breakpoint-down(sm) {
          margin-bottom: 1.25rem;
        }

        a {
          &,
          &:link,
          &:visited {
            display: block;
            position: relative;
            overflow: hidden;
            box-shadow: 0 0 0 0 rgba($color-black, 0);

            @include media-breakpoint-up(sm) {
              float: left;
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba($color-black, 0);
              @include transition();
            }

            &:after {
              content: "";
              position: absolute;
              top: 1rem;
              right: -4rem;
              display: block;
              width: 3rem;
              height: 3rem;
              background: $color-yellow
                url("../images/icons/arrow-right-white.svg") center no-repeat;
              background-size: 1rem;
              border-radius: 50%;
              @include transition();
            }

            img {
              display: block;
              max-width: 100%;
              height: auto;
            }
          }

          &:hover {
            text-decoration: none;
            box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
            transform: scale(1.025);

            &:before {
              background-color: rgba($color-black, 0.5);
            }

            &:after {
              right: 1rem;
            }
          }
        }
      }

      .meta {
        .host {
          color: $color-blue;
          font-size: 0.875rem;
          font-style: italic;
          margin-bottom: 0.5rem;
        }

        .title {
          margin-bottom: 1rem;

          h3 {
            margin: 0;

            a {
              &,
              &:link,
              &:visited {
                font-size: 1.5rem;
                font-weight: $weight-bold;
                color: $color-black;
                border-bottom: 1px solid $color-black;

                @include media-breakpoint-down(sm) {
                  font-size: 1.25rem;
                }
              }

              &:hover,
              &:active,
              &:focus {
                text-decoration: none;
              }
            }
          }
        }

        .info {
          margin-bottom: 1rem;
          font-size: 0.875rem;
          font-weight: $weight-bold;

          .date,
          .city {
            display: inline-block;

            @include media-breakpoint-down(sm) {
              display: block;

              .svg.icon {
                margin-right: 0.5rem;
              }
            }
          }

          @include media-breakpoint-up(sm) {
            .city {
              margin-left: 1rem;
            }
          }
        }

        .description {
          font-size: 0.875rem;
        }
      }

      .register {
        align-self: center;
        text-align: center;

        a.button {
          &.white {
            color: $color-blue;
            padding-left: 2.5rem;
            padding-right: 2.5rem;

            &:hover,
            &:active,
            &:focus {
              color: $color-white;
              background-color: $color-lightblue;
              border-color: $color-lightblue;
            }
          }
        }

        .learn-more {
          margin-top: 0.875rem;

          @include media-breakpoint-down(sm) {
            margin-top: 1.25rem;
          }

          a {
            &,
            &:link,
            &:visited {
              font-size: 1rem;
              font-weight: $weight-bold;
              color: $color-purple;
            }

            &:hover {
              text-decoration: none;
            }

            &:active,
            &:focus {
            }
          }
        }
      }
    }

    .pagination {
      display: block;
      text-align: center;

      .button.icon {
        padding: 0;
        width: 2.8125rem;
        height: 2.8125rem;
        line-height: 2.75;
        font-size: 1rem;
        font-weight: $weight-bold;
        border: none;

        + .button.icon {
          margin-left: 0.5rem;
        }

        &.previous,
        &.next {
          .svg.icon {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.infowindow {
  background-color: $color-white;
  position: relative;
  font-family: $font-family;
  min-width: 20rem;
  max-width: 25rem;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border: 1.5rem solid transparent;
    border-top-color: $color-white;
    width: 0;
    height: 0;
    z-index: 1;
  }

  .host {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-style: italic;
    color: $color-yellow;
  }

  .title {
    margin-bottom: 0.5rem;

    h3 {
      margin-bottom: 0;
      font-family: $font-family;
      font-size: 1.25rem;
      font-weight: $weight-bold;

      a {
        &,
        &:link,
        &:visited {
          color: rgba($color-darkblack, 0.8);
        }

        &:hover {
        }

        &:focus,
        &:active {
        }
      }
    }
  }

  .meta {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: $weight-bold;
    color: $color-lightgray;

    .svg.icon {
      margin-right: 0.25rem;
      fill: rgba($color-black, 0.4);
    }

    &.date {
    }

    &.city {
      margin-bottom: 2.5rem;
    }
  }

  .link {
    .learn-more {
      a {
        &,
        &:link,
        &:visited {
          color: $color-purple;
          font-weight: $weight-bold;
        }

        &:hover {
          text-decoration: none;
        }

        &:focus,
        &:active {
        }
      }
    }

    .register {
      .button.yellow {
        display: block;
        text-align: center;
      }
    }
  }
}
