.featured-resources {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;

  h2 {
    font-weight: $weight-extrabold;
    font-size: 2.125rem;
    color: $color-black;
    margin-bottom: 3rem;
  }
}
