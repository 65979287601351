// Grid settings
$main-sm-columns:    12;
$sidebar-sm-columns: 4;

// Fonts
$font-family:        'Raleway', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

// Colors
$brand-primary:      #27ae60;

$color-blue:         #0054a6;
$color-darkblue:     #0039a6;
$color-lightblue:    #00a3e0;
$color-paleblue:     #e7e9ee;
$color-white:        #fff;
$color-offwhite:     #fafafa;
$color-black:        #231f20;
$color-darkblack:    #000;
$color-yellow:       #fdc82f;
$color-purple:       #6a3883;
$color-lightgreen:   #a8ad00;
$color-green:        #658d1b;
$color-grey:         #d3d2d2;
$color-gray:         #4f4c4d;
$color-lightgray:    #7b7979;
$color-darkgray:     #5f6267;
$color-red:          #b30918;

$colors: (
  "blue"      : $color-blue,
  "darkblue"  : $color-darkblue,
  "lightblue" : $color-lightblue,
  "paleblue"  : $color-paleblue,
  "white"     : $color-white,
  "offwhite"  : $color-offwhite,
  "black"     : $color-black,
  "darkblack" : $color-darkblack,
  "yellow"    : $color-yellow,
  "purple"    : $color-purple,
  "lightgreen": $color-lightgreen,
  "green"     : $color-green,
  "grey"      : $color-grey,
  "gray"      : $color-gray,
  "lightgray" : $color-lightgray,
  "darkgray"  : $color-darkgray,
  "red"       : $color-red
);

// Social networks
$color-facebook:     #4e71a8;
$color-twitter:      #1cb7eb;
$color-linkedin:     #0077b5;
$color-email:        $color-purple;

// Weights
$weight-light:       300;
$weight-regular:     400;
$weight-normal:      400;
$weight-medium:      500;
$weight-semibold:    600;
$weight-bold:        700;
$weight-extrabold:   800;


// Viewport sizes
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
