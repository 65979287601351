.card {
	position: relative;
	background-color: transparent;
	border: none;
	border-radius: 0;

	a {
		&,
		&:link,
		&:visited {
			//margin: 0 1rem 2rem;
			margin-bottom: 2rem;
			position: relative;
			display: block;
			// overflow: hidden;
			min-height: 25rem;
			color: $color-black;
			background-color: #FFF;
			border: 1px solid rgba(#231F20, 0.1);
			border-radius: 0.1875rem;
			box-shadow: 0 0 0 0 rgba($color-black, 0);
			height: 100%;
			@include media-breakpoint-down(xs) {
				margin: 0 0 1rem;
			}
		}

		&:hover {
			text-decoration: none;
			box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
			transform: scale(1.025);

			.photo {
				&:before {
					background-color: rgba($color-black, 0.5);
				}

				&:after {
					right: 1rem;
				}
			}
		}

		&:active,
		&:focus {}
	}

	.host,
	.meta,
	.photo,
	.title {
		:last-child {
			margin-bottom: 0;
		}
	}

	.photo {
		position: relative;
    overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($color-black, 0);
			@include transition();
		}

		&:after {
			content: '';
			position: absolute;
			top: 1rem;
			right: -4rem;
			display: block;
			width: 2rem;
			height: 2rem;
			background: $color-yellow url('../images/icons/arrow-right-white.svg') center no-repeat;
			background-size: 1rem;
			border-radius: 50%;
			@include transition();
		}

		img {
			display: block;
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	// Title
	.title {
		padding: 1rem 2rem 0.5rem;
	}
	// Description
	.description {
		padding: 0.5rem 2rem 1.5rem;

		p {
			font-size: 14px;
		}
	}

	.description,
	.title {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		h3 {
			color: $color-black;
			font-size: 1.25rem;
			font-weight: $weight-bold;
		}
	}

  .resource-type {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.625rem;
    left: 0;
    transform: translateX(-50%);
    @include transition();

    @include max($sm) {
      background-size: cover;
      top: 1rem;
      left: 1rem;
    }

    // &.link {}
  }

	&:nth-child(5n+1) {
		.photo {
			border-bottom: 0.5rem solid $color-blue;
		}
	}

	&:nth-child(5n+2) {
		.photo {
			border-bottom: 0.5rem solid $color-lightblue;
		}
	}

	&:nth-child(5n+3) {
		.photo {
			border-bottom: 0.5rem solid $color-yellow;
		}
	}

	&:nth-child(5n+4) {
		.photo {
			border-bottom: 0.5rem solid $color-lightgreen;
		}
	}

	&:nth-child(5n+5) {
		.photo {
			border-bottom: 0.5rem solid $color-green;
		}
	}
}
