.single-resource {
	article.resource {
		header {
			position: relative;
			overflow: hidden;
			padding: 6rem 0;
			color: $color-white;
			background: $color-purple url('../images/icons/fill-1x.svg') left 30% no-repeat;
			background-size: 100%;

			.header-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: (100% / 3);

				.svg.icon {
					min-width: 100%;
					min-height: 100%;

					#header-image-mask-path {
						transform: translateY(-29%);
					}
				}
			}
		}

		.resource-content {
			background: $color-white url('../images/tile.png') center;
			background-attachment: fixed;

			:last-child {
				margin-bottom: 0;
			}

			h3 {
				font-size: 1.5rem;
				font-weight: $weight-extrabold;
				color: $color-darkblack;
				margin-bottom: 1.5rem;
			}

			h4 {
				font-size: 1.125rem;
				font-weight: $weight-bold;
				color: $color-darkblack;
				margin-bottom: 1.5rem;
			}

			p {
				a {
					&,
					&:link,
					&:visited {
						color: $color-black;
						text-decoration: none;
						border-bottom: 2px solid rgba($color-black, 0.4);
					}

					&:hover {
						text-decoration: none;
						border-bottom-color: $color-black;
					}

					&:focus,
					&:active {}
				}

				a.only-child {
					&,
					&:link,
					&:visited {
						font-weight: $weight-bold;
						color: $color-purple;
						border-bottom: none;

						&:after {
							content: '';
							display: inline-block;
							margin-left: 1rem;
							width: 1rem;
							height: 1rem;
							background: transparent url('../images/icons/arrow-right-purple.svg') center no-repeat;
							background-size: 100% auto;
						}
					}

					&:hover {}

					&:focus,
					&:active {}
				}
			}

			p,
			blockquote,
			ol,
			ul,
			table {
				margin-bottom: 2rem;
			}

			ol,
			ul {
				li {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			ol {
				counter-reset: ol-item-1;
				list-style: none;
				padding-left: 1.5rem;

				li {
					counter-increment: ol-item-1;
					position: relative;

					&:before {
						content: counter(ol-item-1, decimal);
						position: absolute;
						top: 0rem;
						left: -1.5rem;
						width: 1rem;
						line-height: 1;
						font-size: 1.125rem;
						font-weight: $weight-semibold;
						color: $color-blue;
					}
				}

				ol {
					counter-reset: ol-item-2;
					margin-top: 1rem;

					li {
						counter-increment: ol-item-2;

						&:before {
							content: counter(ol-item-2, lower-alpha);
						}
					}

					ol {
						counter-reset: ol-item-3;

						li {
							counter-increment: ol-item-3;

							&:before {
								content: counter(ol-item-3, decimal);
							}
						}
					}
				}
			}

			ul {
				list-style: none;
				padding-left: 1.5rem;

				li {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: 0.5rem;
						left: -1.5rem;
						width: 0.35rem;
						height: 0.35rem;
						background: transparent url('../images/icons/bullet.svg') center no-repeat;
						background-size: 100% 100%;
					}
				}

				ul {
					margin-top: 1rem;

					li {
						&:before {
							top: 0.2rem;
							width: 1rem;
							height: 1rem;
							background-image: url('../images/icons/dash.svg');
						}
					}

					ul {
						li {
							&:before {
								top: 0.5rem;
								width: 0.35rem;
								height: 0.35rem;
								background-image: url('../images/icons/bullet.svg');
							}
						}
					}
				}
			}

			img {
				max-width: 100%;
				height: auto;
			}

			figure {
				a {
					&,
					&:link,
					&:visited {
						position: relative;
					}
				}

				&.wp-caption {
					figcaption {
						font-size: 0.875rem;
						text-align: center;
						padding-top: 0.875rem;
						color: rgba($color-black, 0.4);
					}
				}
			}

			.the-content {
				padding: 4rem 0;
			}
		}
	}
}
