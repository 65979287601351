@each $name, $color in $colors {
  .has-background-color-#{$name} {
    background-color: $color !important;
  }

  // .has-color-#{$name},
  .has-text-color-#{$name} {
    color: $color !important;
  }

  .has-border-color-#{$name} {
    border-color: $color;
  }
}
