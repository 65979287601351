.error404 {
	.page-header {
		position: relative;
		overflow: hidden;
		padding: 6rem 0;
		color: $color-white;
		background: $color-purple url('../images/icons/fill-1x.svg') left 30% no-repeat;
		background-size: 100%;

		.header-image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: (100% / 3);

			.svg.icon {
				min-width: 100%;
				min-height: 100%;

				#header-image-mask-path {
					transform: translateY(-29%);
				}
			}
		}
	}

	.error-content {
		padding: 4rem 0;

		@include media-breakpoint-down(sm) {
			padding: 2rem 0;
		}
	}
}
