.callout {
	position: relative;
	background-color: $color-darkblue;
	color: $color-white;
	padding: 2rem 1.5rem 3rem;
	box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-darkblack, 0.15);
	border-radius: 0.25rem;
	width: 22.8125rem;
	max-width: 100%;
	overflow: hidden;
	margin: 0 auto 2rem;

	@include media-breakpoint-up(md) {
		float: right;
		margin: 0 0 2rem 1rem;
		padding: 3rem 2rem 4rem;
	}

	@include media-breakpoint-up(lg) {
		margin-right: -2rem;
	}

	@include media-breakpoint-up(xl) {
		margin-right: -6rem;
	}

	@media (min-width: 1440px) {
		margin-right: -8rem;
	}

	> * {
		position: relative;
		z-index: 100;
	}

	.bg-icon {
		content: '';
		position: absolute;
		left: 8%;
		top: 60%;
		width: 30rem;
		height: auto;
		z-index: 100;

		.svg.icon {
			width: 100%;
			height: 100%;

			.st0 {
				fill: transparent;
			}
		}
	}

	h2 {
		color: $color-white !important;
		margin-bottom: 2rem !important;
		font-size: 1.25rem !important;
		font-weight: $weight-bold !important;

		span {
			display: block;
			font-weight: $weight-normal;
		}

		@include media-breakpoint-up(md) {
			font-size: 1.5rem !important;
		}
	}

	p {
		font-size: 0.875rem;
		margin-bottom: 2rem;

		@include media-breakpoint-up(md) {
			font-size: 1rem;
		}
	}

	a.button {
		&,
		&:link,
		&:visited {
			color: $color-blue !important;
			padding-left: 2rem;
			padding-right: 2rem;

			&:after {
				display: none !important;
			}
		}

		&:hover {
			border-color: $color-white;
		}
	}
}
