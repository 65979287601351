.single-event {
	article.event {
		header {
			position: relative;
			overflow: hidden;
			padding: 6rem 0;
			color: $color-white;
			background: $color-purple url('../images/icons/fill-1x.svg') left 30% no-repeat;
			background-size: 100%;

			.header-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: (100% / 3);

				.svg.icon {
					position: relative;
					width: 100%;
					height: 100%;

					@include media-breakpoint-up(sm) {
						svg:not(:root) {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							height: 100%;
						}
					}

					@include media-breakpoint-up(lg) {
						svg:not(:root) {
							right: 0;
						}
					}

					image {
						clip-path: url(#mask-desktop);
					}
				}
			}

			@include media-breakpoint-down(xs) {
				background: $color-purple;
				padding: 2rem 1rem 12rem;

				.header-image {
					top: auto;
					left: 0;
					right: 0;
					bottom: 0;
					width: auto;
					height: 10rem;

					.svg.icon {
						svg:not(:root) {
							transform: translateY(-10%);
						}

						#mask-mobile {
							transform: translate(3%, 2.5%);
						}

						image {
							clip-path: url(#mask-mobile);
						}
					}
				}
			}
		}

		.event-content {
			background: $color-white url('../images/tile.png') center;
			background-attachment: fixed;
		}
	}

	.event-registration {
		padding: 4rem 0;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		h2 {
			color: $color-blue;
			font-weight: $weight-bold;
			margin: 0 0 4rem;

			@include media-breakpoint-down(md) {
				margin-bottom: 2rem;
			}
		}
	}

	.event-information {
		padding: 4rem 0;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		h2 {
			color: $color-blue;
			font-weight: $weight-bold;
			margin: 0 0 4rem;

			@include media-breakpoint-down(md) {
				margin-bottom: 2rem;
			}
		}

		h4 {
			&.section-title {
				margin-bottom: 1rem;
				font-size: 0.75rem;
				font-weight: $weight-bold;
				color: rgba($color-black, 0.6);
			}
		}

		.details {
			.event-detail {
				+ .event-detail {
					margin-top: 2rem;
				}

				h3 {
					font-size: 1.25rem;
					font-weight: $weight-bold;
					color: $color-darkblack;
				}

				p {
					margin-bottom: 0;
					font-size: 0.875rem;
				}

				:last-child {
					margin-bottom: 0;
				}
			}
		}

		.about {
			@include media-breakpoint-down(xs) {
				margin-top: 2rem;
			}
		}
	}

	.social-share {
		margin-top: 4rem;
		border-top: 1px solid rgba($color-black, 0.1);
		padding-top: 2rem;
		padding-bottom: 2rem;

		@include media-breakpoint-down(sm) {
			margin: 2rem 1rem 0;
		}

		label {
			display: block;
			margin-bottom: 0.5rem;
			font-size: 1.125rem;

			@include media-breakpoint-up(sm) {
				display: inline-block;
				margin-bottom: 0;
				margin-right: 1rem;
			}
		}

		a.button {
			+ a.button {
				margin-left: 1rem;
			}

			&,
			&:link,
			&:visited {
				color: $color-black;
				position: relative;
				padding: 0.5rem 4rem 0.5rem 1rem;
				box-shadow: none;

				.svg.icon {
					width: 1.25rem;
					height: 1.25rem;
					font-size: 1rem;
					vertical-align: middle;
				}

				.share-count {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 50%;
					text-align: center;
					border-left: 1px solid rgba($color-gray, 0.25);
					font-size: 0.9rem;
					line-height: 2.25;
					font-weight: $weight-normal;
				}

				@include media-breakpoint-down(sm) {
					padding: 0;
					width: 3rem;
					height: 3rem;
					line-height: 2.5rem;

					.svg.icon {
						fill: $color-white;
					}

					.share-count {
						display: none;
					}
				}
			}
		}
	}

	.agenda {
		// background-color: $color-paleblue;
		// background-image: url('../images/icons/fill-1.svg');
		// background-size: cover;
		// background-position: center;
		// background-repeat: no-repeat;
		// background: $color-paleblue url('../images/icons/fill-1.svg') center no-repeat;
		// background-size: cover;
		background: $color-paleblue url('../images/agenda-bg.png') center no-repeat;
		background-size: 125% auto;
		padding: 4rem 0;
		position: relative;
		overflow: hidden;

		@include max( $sm ) {
			background-size: cover;
		}

		> * {
			position: relative;
			z-index: 100;
		}

		.bg-icon {
			// display: none;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateY(-25%);
			z-index: 1;

			.svg.icon {
				min-width: 100%;
				min-height: 200%;
				fill: $color-darkgray;
			}
		}

		h2 {
			color: $color-blue;
			font-weight: $weight-bold;
			margin: 0 0 1rem;
		}

		.days {
			margin-bottom: 1rem;

			a {
				&,
				&:link,
				&:visited {
					font-size: 1.5rem;
					color: $color-black;
					text-decoration: none;
					text-decoration: underline;
				}

				&.active {
					font-weight: $weight-bold;
					text-decoration: none;
				}

				&:hover {
					text-decoration: none;
				}

				+ a {
					&,
					&:link,
					&:visited {
						position: relative;
						margin-left: 3.5rem;

						&:before {
							content: '';
							position: absolute;
							left: -1.75rem;
							top: 0;
							bottom: 0;
							width: 1px;
							background-color: rgba($color-black, 0.5);
						}
					}
				}
			}
		}

		.pdf {
			margin-bottom: 1rem;

			a {
				&,
				&:link,
				&:visited {
					font-size: 1.25rem;
					color: $color-purple;

					.svg.icon {
						vertical-align: middle;
					}

					span {
						text-decoration: underline;
					}
				}

				&:hover {
					text-decoration: none;

					span {
						text-decoration: none;
					}
				}

				&:focus,
				&:active {}
			}
		}

		.container {
			@include media-breakpoint-up(lg) {
				max-width: 60rem;
			}

			.row {
				margin-bottom: 1.5rem;
			}
		}

		.time {
			font-weight: $weight-bold;
		}

		.description {
			font-size: 1.125rem;

			:last-child {
				margin-bottom: 0;
			}

			.presentations {
				margin-top: 1.25rem;

				h3 {
					text-transform: uppercase;
					font-size: 1rem;
					font-weight: $weight-extrabold;

					a {
						&,
						&:link,
						&:visited {
							display: inline-block;
							margin-left: 1rem;
							// border-left: 1px solid rgba($color-lightgray, 0.5);
							// padding-left: 1rem;
							font-size: 1rem;
							font-weight: $weight-normal;
							text-transform: none;
							color: $color-purple;
							text-decoration: underline;

							// .svg.icon {
							// 	margin-right: 0.25rem;
							// }

							// span {
							// 	@include transition();
							// 	border-bottom: 1px solid $color-purple;
							// }
						}

						&:hover {
							text-decoration: none;

							// span {
							// 	border-bottom-color: transparent;
							// }
						}

						&:active,
						&:focus {}
					}
				}

				ul {
					list-style: none;
					margin-top: 2rem;
					border-left: 2px solid rgba($color-black, 0.15);
				}

				.presentation {
					+ .presentation {
						margin-top: 1.5rem;
					}

					h4 {
						font-size: 1.125rem;
						font-weight: $weight-bold;
					}

					p {
						font-size: 0.875rem;
						font-style: italic;
					}
				}
			}
		}
	}

	.featured-speakers {
		padding: 4rem 0 1rem;

		h2 {
			color: $color-blue;
			font-weight: $weight-bold;
			margin: 0 0 4rem;
		}

		.speakers {
			min-height: 22.8125rem;

			&.slider {
				position: relative;
				@include transition();

				.controls {
					position: absolute;
					z-index: 1000;
					top: (22.8125rem / 2);
					left: 0;
					width: 22.8125rem;
					max-width: 100%;
					transform: translateY(-50%);

					@include media-breakpoint-down(sm) {
						top: 0;
						width: 100%;
						height: 0;
						padding-bottom: 100%;
						transform: none;
					}

					.prev,
					.next {
						content: '';
						position: absolute;
						top: 0;
						transform: translateY(-50%);
						width: 3rem;
						height: 3rem;
						background: $color-white center no-repeat;
						background-size: 0.5rem;
						border-radius: 50%;
						box-shadow: 0 0.25rem 0.5rem 0.25rem rgba($color-darkblack, 0.08);
						cursor: pointer;
					}

					.prev {
						background-image: url('../images/icons/chevron-left.svg');
						left: 0;
						transform: translate(-25%, -50%);

						@include media-breakpoint-up(sm) {
							transform: translate(-50%, -50%);
						}
					}

					.next {
						background-image: url('../images/icons/chevron-right.svg');
						right: 0;
						transform: translate(25%, -50%);

						@include media-breakpoint-up(sm) {
							transform: translate(50%, -50%);
						}
					}
				}

				.counter {
					position: absolute;
					z-index: 1000;
					top: 20.5rem;
					left: 0;
					width: 22.8125rem;
					max-width: 100%;
					padding: 0 1rem 1rem 0;
					font-size: 0.875rem;
					text-align: right;
					color: $color-white;
					text-shadow: 0.0625rem 0.0625rem 0.25rem $color-darkblack;

					@include media-breakpoint-down(sm) {
						top: 19.1875rem;
						bottom: auto;
						width: auto;
						right: 1.1875rem;
					}
				}

				.speaker {
					display: none;

					@include media-breakpoint-up(md) {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 100;
					}

					.photo {
						position: relative;
						transform: scale(0.85) translateX(-4rem);
						opacity: 0.25;
						width: 22.8125rem;
						height: 0;
						max-width: 100%;
						padding-bottom: 22.8125rem;
						overflow: hidden;

						@include media-breakpoint-down(sm) {
							width: 100%;
							padding-bottom: 100%;
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
						}
					}

					.content {
						padding-top: 2rem;
						opacity: 0;
						transform: scale(0.85) translateX(4rem);
					}

					&.active {
						display: block;
						opacity: 1;

						@include media-breakpoint-up(md) {
							z-index: 500;
						}

						.photo {
							transform: scale(1) translateX(0);
							opacity: 1;
						}

						.content {
							opacity: 1;
							transform: scale(1) translateX(0);
						}
					}
				}
			}

			.speaker {
				@include transition();
				@include clearfix();

				.photo {
					position: relative;
					@include transition();

					@include media-breakpoint-up(md) {
						float: left;
					}

					img {
						position: relative;
						z-index: 10;
					}

					&:after {
						content: '';
						position: absolute;
						z-index: 50;
						top: 75%;
						right: 0;
						bottom: 0;
						left: 0;
						background-image: linear-gradient(-180deg, rgba($color-darkblack, 0) 20%, rgba($color-darkblack, 0.2) 100%);
					}
				}

				.content {
					@include transition();

					@include media-breakpoint-up(md) {
						margin-left: 26rem;
						padding-top: 0;
					}

					h3 {
						font-size: 1.5rem;
						font-weight: $weight-extrabold;
						margin-bottom: 0;
						color: $color-darkblack;
					}

					h4 {
						&.title {
							margin: 0.25rem 0 0;
							font-size: 1.25rem;
						}
					}

					p {
						&.credentials {
							font-size: 0.875rem;
							font-style: italic;
							margin: 2rem 0;
						}
					}

					.bio {
						position: relative;
						font-size: 1.125rem;
						max-height: 10rem;
						overflow: hidden;
						// @include transition();
						cursor: pointer;
            padding-bottom: 0;

            p {
              margin-bottom: 0;

              + p {
                margin-top: 1rem;
              }
            }

						&.expanded {
							max-height: none;
							background-color: $color-white;
              padding-bottom: 1.75rem;

							.read-more {
								&:before {
									content: '';
									margin: 0;
								}

								.svg.icon {
									transform: rotate(-180deg);
								}
							}
						}

						.read-more {
							display: inline-block;
							margin-left: 1rem;
							font-size: 1rem;
							font-weight: $weight-bold;
							color: $color-purple;
							white-space: nowrap;
							position: absolute;
							right: 0;
							bottom: 0;
							background-color: $color-white;
							padding: 0;
							@include transition();

							&:before {
								content: ' ... ';
								display: inline-block;
								margin: 0 0.25rem;
								@include transition();
							}

							span {
								@include transition();
								border-bottom: 1px solid $color-purple;
								white-space: nowrap;
							}

							.svg.icon {
								vertical-align: middle;
								@include transition();
							}

							&:hover {
								text-decoration: none;

								span {
									border-bottom-color: transparent;
								}
							}
						}
					}
				}
			}
		}
	}

	.event-resources {
		margin-top: 4rem;
		border-top: 1px solid rgba($color-black, 0.1);
		padding-top: 2rem;
		padding-bottom: 2rem;

		h2 {
			color: $color-blue;
			font-size: 2.375rem;
			font-weight: $weight-bold;
			margin-bottom: 2.625rem;
		}

		a {
			&,
			&:link,
			&:visited {
				font-size: 1.25rem;
				font-weight: $weight-bold;
				color: $color-black;

				.svg.icon {
					display: inline-block;
					width: 3.25rem;
					height: 3.25rem;
					color: transparent;
					fill: transparent;
					stroke: $color-black;
					vertical-align: middle;
					margin-right: 1rem;

					.color {
						stroke: $color-purple;
					}
				}

				.resource-type {
					width: 2rem;
					height: 2rem;
					position: absolute;
					top: 0.625rem;
					left: 0;
					transform: translateX(-50%);

					@include max($sm) {
						background-size: cover;
						top: 1rem;
						left: 1rem;
					}

					// &.link {}
				}

				.title {
					padding-top: 1.5rem;
				}

				.title,
				.description {
					// padding: 1.5rem 1.875rem;
					padding-left: 1.875rem;
					padding-right: 1.875rem;
				}

				.description {
					font-weight: $weight-medium;

					p {
						margin-bottom: 1rem;
					}

					:last-child {
						margin-bottom: 0;
					}
				}

				&.all-resources {
					font-size: 1rem;
					color: $color-purple;
					font-weight: $weight-bold;

					&:after {
						content: '';
						display: inline-block;
						margin-left: 0.5rem;
						width: 1rem;
						height: 1rem;
						vertical-align: middle;
						background: transparent url('../images/icons/arrow-right-purple.svg') center no-repeat;
						background-size: 100% auto;
					}
				}
			}

			&:hover {
				text-decoration: none;
			}

			&:active,
			&:focus {
				text-decoration: none;
			}
		}
	}
}

.nav-active.single-event article.event nav.event-nav {
	position: unset;
	z-index: -1;
}

.flex-grow-2 {
	flex-grow: 2;
}


// -----------------------------------------------------------------------------
//! Event Nav
// -----------------------------------------------------------------------------

.event-nav {
	background: #fff;
	border-bottom: 1px solid #E9E8E8;

	&.is-fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99999;

		~ .buffer {
			height: 61px;

			@include min( $md ) {
				height: 95px;
			}
		}
	}

	@include min( $md ) {
		border-bottom: 2px solid rgba($color-black, 0.1);
	}

	.contain {
		display: flex;
		align-items: flex-start;
		padding: 0;
		height: 61px;

		@include min( $md ) {
			height: 95px;
			padding: 24px 2rem;
			align-items: center;
		}
	}

	ul {
		flex: 1 1 220px;
		padding: 0;
		margin: 0;
		height: 61px;
		overflow: hidden;

		&.is-open {
			overflow: visible;
			z-index: 99999;
		}

		@include min( $md ) {
			height: auto;
			flex-basis: auto;
		}
	}

	li {
		list-style-type: none;
		display: block;
		max-width: 220px;

		@include min( $md ) {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 45px;
			}
		}

		@include min( 850px ) {
			&:not(:last-child) {
				margin-right: 70px;
			}
		}
	}

	li a {
		display: block;
		padding: 23px 20px 22px;
		font-weight: $weight-extrabold;
		font-size: 14px;
		line-height: 16px;
		color: rgba( #000, .7);
		text-transform: uppercase;
		background-color: #fff;
		box-shadow: 0 0.35rem 0.65rem 0 rgba(35,31,32,.1);
		border-top: 1px solid #e9e8e8;

		&.active {
			border: 0;
			display: none;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}

		&:hover {
			color: $color-darkblue;
		}

		@include min( $md ) {
			box-shadow: none;
			padding: 0;
			border: 0;

			&.active {
				display: block;
				color: $color-darkblue;
			}
		}

		@include min( 850px ) {
			font-size: 16px;
			line-height: 19px;
		}
	}

	// Hide the poster on desktop
	li:first-of-type {
		@include min( $md ) {
			display: none;
		}
	}

	li a.poster {
		background-image: url(../images/icons/chevron-down-gray.svg);
		background-position: right 23px center;
		background-size: 11px 7px;
		background-repeat: no-repeat;
		box-shadow: none;
		border: 0;

		@include min( $md ) {
			display: none;
		}

		&:hover {
			color: rgba( #000, .7);
		}

		&.active {
			display: block;

			@include min( $md ) {
				display: none;
			}
		}
	}

	.button {
		flex: 0 0 110px;
		font-weight: $weight-extrabold;
		font-size: 13px;
		padding: 19px 10px;
		text-align: center;

		@include max( $md ) {
			border-radius: 0;
			box-shadow: none;

			&:hover {
				transform: none;
			}
		}

		@include min( $md ) {
			padding-top: 11px;
			padding-bottom: 11px;
			min-width: 178px;
		}
	}
}
