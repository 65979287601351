.gform_wrapper {
	.validation_error {
		color: $color-red;
		padding: 1rem 2.5rem;
	}

	.gform_body {
		ul {
			list-style: none;
			padding-left: 0 !important;
			@include clearfix;
			margin-left: -0.5rem;
			margin-right: -0.5rem;

			li {
				display: block;
				width: 100%;
				float: none;
				clear: both;
				padding: 0 0.5rem;
				margin: 0 0 1rem !important;
				@include transition();

				@include media-breakpoint-up(sm) {
					float: left;
					clear: none;
					position: relative;

					&.gfield_size_small {
						width: (100% / 3);

						&.gfield_type {
							&_checkbox,
							&_radio {
								.ginput_container {
									ul {
										display: block;
										margin-left: 0;
										margin-right: 0;

										li {
											display: block;
											width: 100%;
											max-width: 100%;
											margin-bottom: 0 !important;
											padding: 0;
										}
									}
								}
							}
						}
					}

					&.gfield_size_medium {
						width: 50%;
					}

					&.gfield_size_large {
						width: 100%;
						clear: both;
					}
				}

				&.gfield_type_fieldgroupclose,
				&.gform_validation_container,
				&.gfield_visibility_hidden,
				&.gfield_hidden,
				&:before {
					display: none;
				}

				&.gfield_contains_required {}

				label {
					position: absolute;
					z-index: 500;
					top: 50%;
					left: 1rem;
					margin-bottom: 0;
					padding: 0 6px 0 8px;
					font-size: 1.125rem;
					line-height: 1;
					color: rgba($color-black, 0.4);
					background-color: transparent;
					@include transition();
					transform: translateY(-50%);

					.gfield_required {
						font-weight: $weight-bold;
						color: $color-red;
					}
				}

				.ginput_container {
					position: relative;
					width: 100%;
					height: 3.125rem;
					background-color: $color-white;
					border: 1px solid rgba($color-black, 0.20);
					border-radius: 0.1875rem;
					padding: 0.1875rem;
					box-shadow: 0 0 0 0 rgba($color-blue, 0);
					@include transition();

					input,
					textarea,
					select {
						position: relative;
						z-index: 600;
						color: $color-black;
						padding: 0 0.5rem;

						&:focus {
							outline: none;
						}

						&.small,
						&.medium,
						&.large {
							font-size: 1rem;
							font-weight: $weight-normal;
						}
					}

					input {
						&[type="text"],
						&[type="email"],
						&[type="number"],
						&[type="tel"],
						&[type="password"] {
							border: none;
							background: transparent;
							width: 100%;
							height: 100%;
							// padding: 0;
							margin: 0;
						}
					}
				}

				.gfield_description {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					padding: 0.125rem 1.5rem;
					font-size: 0.75rem;
					color: rgba($color-black, 0.4);

					&.validation_message {
						color: $color-red;
					}
				}

				&.filled {
					label {
						top: -0.5rem;
						font-size: 0.875rem;
						font-weight: $weight-bold;
						color: rgba($color-black, 0.4);
						background-color: $color-white;
						transform: translateY(0);
					}

					.ginput_container {
						border-color: rgba($color-black, 0.2);
						box-shadow: 0 0 0 0 rgba($color-blue, 0);

						input,
						textarea,
						select {
							color: rgba($color-black, 0.8);
						}
					}
				}

				&.focused {
					label {
						top: -0.5rem;
						font-size: 0.875rem;
						font-weight: $weight-bold;
						color: $color-blue;
						background-color: $color-white;
						transform: translateY(0);
					}

					.ginput_container {
						border-color: rgba($color-blue, 0.75);
						box-shadow: 0 0 0.1875rem 0 rgba($color-blue, 0.3);
					}
				}



				&.valid,
				&.invalid {
					.ginput_container {
						position: relative;

						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 0.875rem;
							width: 0.75rem;
							height: 0.75rem;
							transform: translateY(-50%);
							background: transparent center no-repeat;
							background-size: 100% auto;
						}
					}
				}

				&.valid {
					label {
						color: $color-lightgreen;
					}

					.ginput_container {
						border-color: $color-lightgreen;

						&:after {
							background-image: url('../images/icons/valid.svg');
						}
					}
				}

				&.gfield_error,
				&.invalid {
					label {
						color: rgba($color-red, 0.75);
					}

					.ginput_container {
						border-color: rgba($color-red, 0.5);
						box-shadow: 0 0 0.1875rem 0 rgba($color-red, 0.3);

						&:after {
							background-image: url('../images/icons/invalid.svg');
						}

						input,
						textarea {
							color: $color-red;
						}
					}
				}

				&.gfield_error {
					label {
						color: rgba($color-red, 0.75);
					}

					.ginput_container {
						border-color: rgba($color-red, 0.5);
						box-shadow: 0 0 0 0 rgba($color-red, 0);

						input,
						textarea {
							color: $color-red;
						}
					}
				}

				&.gfield_type {
					&_fieldgroupopen {
						margin-bottom: 0 !important;

						&:first-of-type {
							.gfield_type_html {
								padding-top: 0;
								border-top: 0;
							}
						}
					}

					&_html {
						width: 100%;
						border-top: 1px solid rgba($color-black, 0.1);
						padding-top: 2rem;

						h3 {
							font-size: 1.25rem;
							font-weight: $weight-bold;

							&:last-child,
							& {
								margin-bottom: 1rem;
							}

							&:before {
								content: '';
								display: inline-block;
								width: 1.125rem;
								height: 1.125rem;
								background-color: transparent;
								border: 2px solid rgba($color-black, 0.2);
								border-radius: 50%;
								margin-right: 0.5rem;
								@include transition();
							}
						}

						&.valid {
							h3 {
								&:before {
									border-color: $color-lightgreen;
									background: transparent url('../images/icons/valid.svg') center no-repeat;
									background-size: 75% auto;
								}
							}
						}
					}

					&_address {
						width: 100%;
						margin-bottom: 0;

						label {
							&.gfield_label_before_complex {
								display: none;
							}
						}

						.ginput_container {
							width: auto;
							padding: 0;
							margin: 0 -0.5rem;
							border: none;
							background: none;
							height: auto;
							box-shadow: none;
							@include clearfix;

							.ginput_span_container {
								display: block;
								width: 100%;
								float: none;
								padding: 0 0.5rem;
								margin: 0 0 1rem !important;
								@include transition();

								@include media-breakpoint-up(sm) {
									float: left;

									&.full {
										width: 100%;
									}

									&.left,
									&.right {
										width: 50%;
									}
								}

								&.focused {
									label {
										top: -0.5rem;
										font-size: 0.875rem;
										font-weight: $weight-bold;
										color: $color-blue;
										background-color: $color-white;
										transform: translateY(0);
									}

									@include media-breakpoint-up(sm) {
										span[class^="ginput_"] {
											border-color: rgba($color-blue, 0.75);
											box-shadow: 0 0 0.1875rem 0 rgba($color-blue, 0.3);
										}
									}
								}

								&.filled {
									label {
										top: -0.5rem;
										font-size: 0.875rem;
										font-weight: $weight-bold;
										color: rgba($color-black, 0.4);
										background-color: $color-white;
										transform: translateY(0);
									}

									span[class^="ginput_"] {
										border-color: rgba($color-black, 0.2);
										box-shadow: 0 0 0 0 rgba($color-blue, 0);

										input,
										textarea,
										select {
											color: rgba($color-black, 0.8);
										}
									}
								}

								&.select_dropdown {
									span[class^="ginput_"] {
										border: none;
										background: none;
										box-shadow: none;
										height: auto;
										padding: 0;
									}
								}

								span[class^="ginput_"] {
									position: relative;
									display: block;
									width: 100%;
									height: 3.125rem;
									background-color: $color-white;
									border: 1px solid rgba($color-black, 0.20);
									border-radius: 0.1875rem;
									padding: 0.1875rem;
									box-shadow: 0 0 0 0 rgba($color-blue, 0);
									@include transition();
								}
							}
						}
					}

					&_name {
						width: 100%;
						margin-bottom: 0 !important;

						label {
							&.gfield_label_before_complex {
								display: none;
							}
						}

						.ginput_container {
							width: auto;
							padding: 0;
							margin: 0 -0.5rem;
							border: none;
							background: none;
							height: auto;
							box-shadow: none;
							@include clearfix;

							@include media-breakpoint-up(sm) {
								display: flex;
								flex-wrap: nowrap;

								.ginput_span_container {
									&.prefix,
									&.suffix,
									&.middle {
										flex-basis: 20%;
									}

									&.first,
									&.last {
										flex-basis: 50%;
										flex-shrink: 2;
									}
								}
							}

							.ginput_span_container {
								display: block;
								width: 100%;
								float: none;
								padding: 0 0.5rem;
								margin: 0 0 1rem !important;
								@include transition();

								&.focused {
									label {
										top: -0.5rem;
										font-size: 0.875rem;
										font-weight: $weight-bold;
										color: $color-blue;
										background-color: $color-white;
										transform: translateY(0);
									}

									@include media-breakpoint-up(sm) {
										span[class^="ginput_"] {
											border-color: rgba($color-blue, 0.75);
											box-shadow: 0 0 0.1875rem 0 rgba($color-blue, 0.3);
										}
									}
								}

								&.filled {
									label {
										top: -0.5rem;
										font-size: 0.875rem;
										font-weight: $weight-bold;
										color: rgba($color-black, 0.4);
										background-color: $color-white;
										transform: translateY(0);
									}

									span[class^="ginput_"] {
										border-color: rgba($color-black, 0.2);
										box-shadow: 0 0 0 0 rgba($color-blue, 0);

										input,
										textarea,
										select {
											color: rgba($color-black, 0.8);
										}
									}
								}

								&.select_dropdown {
									span[class^="ginput_"] {
										border: none;
										background: none;
										box-shadow: none;
										height: auto;
										padding: 0;
									}
								}

								span[class^="name_"] {
									position: relative;
									display: block;
									width: 100%;
									height: 3.125rem;
									background-color: $color-white;
									border: 1px solid rgba($color-black, 0.20);
									border-radius: 0.1875rem;
									padding: 0.1875rem;
									box-shadow: 0 0 0 0 rgba($color-blue, 0);
									@include transition();
								}

								span {
									&.name_prefix {
										&.name_prefix_select {
											padding: 0;
											border: none;
										}
									}
								}
							}
						}

						.selectric-wrapper {
							.selectric {
								height: 3.125rem;
								border-radius: 0.1875rem;
								background-color: rgba($color-black, 0.05);
								border-color: rgba($color-black, 0.2);
								@include transition();

								.label {
									margin: 0 3rem 0 1.5rem;
									height: 3.125rem;
									line-height: 3.125rem;
									font-size: 1.125rem;
									color: rgba($color-black, 0.4);
									@include transition();
								}

								.button {
									width: auto;
									height: auto;
									bottom: 0;
									border: none;
									background-color: transparent;
									box-shadow: none;

									&:after {
										width: 0.5rem;
										height: 0.5rem;
										border: none;
										background: transparent url(../images/icons/chevron-up.svg) center no-repeat;
										background-size: 100% auto;
										@include transition();
										transform: rotate(180deg);
									}
								}
							}

							&.selectric-open {
								.selectric {
									background-color: $color-white;
									border-color: rgba($color-blue, 0.75);
									box-shadow: 0 0 0.1875rem 0 rgba($color-blue, 0.3);

									.button {
										&:after {
											transform: rotate(0deg);
										}
									}

									.label {
										color: rgba($color-black, 0.8);
									}
								}
							}

							&.selectric-changed {
								.selectric {
									background-color: $color-white;
									border-color: rgba($color-blue, 0.75);
									box-shadow: none;

									.label {
										color: rgba($color-black, 0.8);
									}
								}

								+ label {
									opacity: 0;
								}
							}

							&.selectric-hover {
								.selectric {
									box-shadow: 0 0 0.1875rem 0 rgba($color-black, 0.3);
								}
							}
						}

						.selectric-items {
							background-color: $color-white;
							border: 1px solid $color-grey;
							box-shadow: 0 0.125rem 0.1875rem 0 rgba($color-darkblack, 0.1);
							border-radius: 0.1875rem;

							ul {
								font-size: 1rem;
								margin: 0;
								padding: 0;

								:last-child {
									border-bottom: none;
								}
							}

							li {
								width: auto;
								float: none;
								font-size: 1rem;
								padding: 0.875rem 1rem;
								margin: 0 !important;
								border-bottom: 1px solid rgba($color-black, 0.1);

								&:before {
									content: '';
									display: inline-block;
									width: 0.5rem;
									height: 0.5rem;
									position: initial;
									top: auto;
									left: auto;
									background: none;
									margin-right: 0.5rem;
									vertical-align: middle;
								}

								&:hover,
								&.selected,
								&.highlighted {
									background-color: rgba($color-blue, 0.05);
									color: $color-blue;
								}

								&.selected {
									&:before {
										background: transparent url('../images/icons/selected.svg') center no-repeat;
										background-size: 100% auto;
									}
								}
							}
						}
					}

					&_textarea {
						label {
							top: 1.5rem;
						}

						&.focused {
							label {
								top: -0.5rem;
							}
						}

						.ginput_container {
							height: auto;

							textarea {
								display: block;
								width: 100%;
								background-color: transparent;
								border: none;
							}
						}
					}

					&_select,
					&_checkbox,
					&_radio {
						label {
							margin-bottom: 0.5rem;
							font-size: 1rem;
							font-weight: $weight-bold;
							position: relative;
							top: auto;
							left: auto;
							color: rgba($color-darkblack, 0.8);
							padding-left: 0;
							padding-right: 0;
							transform: scale(1);
						}

						.ginput_container {
							border: none;
							background: none;
							box-shadow: none;
							height: auto;
							padding: 0;

							ul {
								label {
									font-weight: $weight-normal;
									color: $color-black;
								}
							}
						}
					}

					&_address,
					&_select {
						.selectric-wrapper {
							.selectric {
								height: 3.125rem;
								border-radius: 0.1875rem;
								background-color: rgba($color-black, 0.05);
								border-color: rgba($color-black, 0.2);
								@include transition();

								.label {
									margin: 0 3rem 0 1.5rem;
									height: 3.125rem;
									line-height: 3.125rem;
									font-size: 1.125rem;
									color: rgba($color-black, 0.4);
									@include transition();
								}

								.button {
									width: auto;
									height: auto;
									bottom: 0;
									border: none;
									background-color: transparent;
									box-shadow: none;

									&:after {
										width: 0.5rem;
										height: 0.5rem;
										border: none;
										background: transparent url(../images/icons/chevron-up.svg) center no-repeat;
										background-size: 100% auto;
										@include transition();
										transform: rotate(180deg);
									}
								}
							}

							&.selectric-open {
								.selectric {
									background-color: $color-white;
									border-color: rgba($color-blue, 0.75);
									box-shadow: 0 0 0.1875rem 0 rgba($color-blue, 0.3);

									.button {
										&:after {
											transform: rotate(0deg);
										}
									}

									.label {
										color: rgba($color-black, 0.8);
									}
								}
							}

							&.selectric-changed {
								.selectric {
									background-color: $color-white;
									border-color: rgba($color-blue, 0.75);
									box-shadow: none;

									.label {
										color: rgba($color-black, 0.8);
									}
								}

								+ label {
									opacity: 0;
								}
							}

							&.selectric-hover {
								.selectric {
									box-shadow: 0 0 0.1875rem 0 rgba($color-black, 0.3);
								}
							}
						}

						.selectric-items {
							background-color: $color-white;
							border: 1px solid $color-grey;
							box-shadow: 0 0.125rem 0.1875rem 0 rgba($color-darkblack, 0.1);
							border-radius: 0.1875rem;

							ul {
								font-size: 1rem;
								margin: 0;
								padding: 0;

								:last-child {
									border-bottom: none;
								}
							}

							li {
								width: auto;
								float: none;
								font-size: 1rem;
								padding: 0.875rem 1rem;
								margin: 0 !important;
								border-bottom: 1px solid rgba($color-black, 0.1);

								&:before {
									content: '';
									display: inline-block;
									width: 0.5rem;
									height: 0.5rem;
									position: initial;
									top: auto;
									left: auto;
									background: none;
									margin-right: 0.5rem;
									vertical-align: middle;
								}

								&:hover,
								&.selected,
								&.highlighted {
									background-color: rgba($color-blue, 0.05);
									color: $color-blue;
								}

								&.selected {
									&:before {
										background: transparent url('../images/icons/selected.svg') center no-repeat;
										background-size: 100% auto;
									}
								}
							}
						}
					}

					&_checkbox {
						.gfield_checkbox {
							margin-top: 0;
							padding-left: 0;

							@include media-breakpoint-up(sm) {
								@include make-row();
							}

							li {
								position: relative;
								overflow: hidden;
								margin-bottom: 1rem !important;

								@include media-breakpoint-up(sm) {
									@include make-col(1, 2);
								}

								input {
									position: absolute;
									left: -100rem;

									&:hover {
										~ label {
											&:before {
												border-color: rgba($color-black, 0.2);
											}
										}
									}

									&:focus,
									&:checked {
										~ label {
											&:before {
												border-color: rgba($color-black, 0.75);
											}

											&:after {
												background: transparent url('../images/icons/selected.svg') center no-repeat;
											}
										}
									}
								}

								label {
									position: relative;
									transform: translateY(0);
									padding-left: 1.5rem;

									&:before,
									&:after {
										content: '';
										position: absolute;
										top: 50%;
										left: 0;
										border-radius: 0.1875rem;
										width: 1.125rem;
										height: 1.125rem;
										@include transition();
									}

									&:before {
										border: 1px solid rgba($color-black, 0.2);
										transform: translateY(-50%);
									}

									&:after {
										background-color: $color-white;
										transform: translateY(-50%) scale(0.55);
									}
								}
							}
						}
					}

					&_radio {
						.gfield_radio {
							margin-top: 0;
							padding-left: 0;

							li {
								position: relative;
								overflow: hidden;
								margin-bottom: 1rem !important;

								@include media-breakpoint-up(sm) {
									float: left;
									width: 50%;
								}

								input {
									position: absolute;
									left: -100rem;

									&:hover {
										~ label {
											&:before {
												border-color: rgba($color-black, 0.2);
											}
										}
									}

									&:focus,
									&:checked {
										~ label {
											&:before {
												border-color: rgba($color-black, 0.75);
											}

											&:after {
												background-color: rgba($color-blue, 0.75);
											}
										}
									}
								}

								label {
									display: block;
									position: relative;
									transform: translateY(0);
									padding: 0.25rem 0 0.25rem 1.5rem;

									&:before,
									&:after {
										content: '';
										position: absolute;
										top: 50%;
										left: 0;
										border-radius: 50%;
										width: 1.125rem;
										height: 1.125rem;
										@include transition();
									}

									&:before {
										border: 1px solid rgba($color-black, 0.2);
										transform: translateY(-50%);
									}

									&:after {
										background-color: $color-white;
										transform: translateY(-50%) scale(0.55);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.gform_footer {
		text-align: center;

		button,
		input {
			&[type="submit"] {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
			}
		}
	}
}
