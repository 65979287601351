a {
	&:link,
	&:visited {
		color: $color-blue;
		@include transition();
	}

	&:hover {
		color: $color-black;
	}

	a:focus,
	&:active {
		color: $color-black;
		text-decoration: none;
	}

}
