.icon:not(.button) {
	display: inline-block;
	width: 3.125rem;
	height: 3.125rem;

	&.svg {
		width: 0.85em;
		height: 0.85em;
		fill: inherit;

		&.color {
			color: transparent;
			fill: transparent;
		}
	}

	&.white {
		color: $color-white;
		fill: $color-white;
	}

	&.blue {
		color: $color-blue;
		fill: $color-blue;
	}

	&.lightblue {
		color: $color-lightblue;
		fill: $color-lightblue;
	}

	&.black {
		color: $color-black;
		fill: $color-black;
	}

	&.gray {
		color: $color-gray;
		fill: $color-gray;
	}

	&.purple {
		color: $color-purple;
		fill: $color-purple;
	}

	&.facebook {
		color: $color-facebook;
		fill: $color-facebook;
	}

	&.twitter {
		color: $color-twitter;
		fill: $color-twitter;
	}

	&.email {
		color: $color-email;
		fill: $color-email;
	}

	&.linkedin {
		color: $color-linkedin;
		fill: $color-linkedin;
	}

	&.stroke {
		fill: none;
		stroke-width: 2px;

		&.white {
			stroke: $color-white;
		}

		&.blue {
			stroke: $color-blue;
		}

		&.lightblue {
			stroke: $color-lightblue;
		}

		&.black {
			stroke: $color-black;
		}

		&.gray {
			stroke: $color-gray;
		}
	}
}
