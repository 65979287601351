
// -----------------------------------------------------------------------------
//! Structure
// -----------------------------------------------------------------------------

.banner .contain {
	max-width: 1364px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}


// -----------------------------------------------------------------------------
//! Utility
// -----------------------------------------------------------------------------

.banner .utility {
	display: none;
	background-color: $color-darkblue;
	padding: 10px 0;

	@include min( $md ) {
		display: block;
	}

	.brand {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 14px;
		color: #fff;
		letter-spacing: 0.5px;
		opacity: 0.9;
		-webkit-font-smoothing: antialiased;

		img {
			width: 6.75rem;
			max-height: 2.1875rem;
		}
	}

	.nav {
		margin-left: auto;
		align-items: center;
	}

	a:hover {
		opacity: 1;
		text-decoration: none;
	}
}


// -----------------------------------------------------------------------------
//! Main
// -----------------------------------------------------------------------------

.banner .main {
	position: static;
	padding: 10px 0;
	border-top: 4px solid $color-darkblue;
	background: #fff;
	z-index: 110;

	@include min( $md ) {
		border-top: 0;
		padding: 0;
	}

	.brand {
		display: inline-block;
		flex: 0 0 128px;
		max-height: 50px;

		@include min( $md ) {
			flex-basis: 145px;
			max-height: none;
		}

		@include min( $lg ) {
			flex-basis: 180px;
		}

		img {
			display: block;
			max-width: 100%;
		}
	}

	.nav-toggle {
		margin-left: auto;
	}

	nav {
		position: absolute;
		top: 0;
		right: -320px;
		top: 74px;
		bottom: 0;
		width: 280px;
		background: #fff;
		z-index: 50;
		transition: .4s;
		overflow: auto;

		@include min( $md ) {
			flex: 0 1 735px;
			position: static;
			top: auto;
			right: auto;
			width: auto;
			height: auto;
			margin-left: auto;
			overflow: visible;
		}
	}
}

@include max( $md ) {
	body.nav-active .banner nav {
		right: 0;
	}
}


// -----------------------------------------------------------------------------
//! Nav Toggle
// -----------------------------------------------------------------------------

.nav-toggle {
	display: block;
	width: 25px;
	height: 22px;
	padding: 3px;

	@include min( $md ) {
		display: none;
	}

	&:hover,
	&:active,
	&:focus {
		outline: 0;
		opacity: .8;
	}

	.icon.svg {
		width: 100%;
		height: 100%;
	}
}
