.home {
	.featured-events {
		background-color: $color-paleblue;
		padding: 4rem 0 2rem;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			padding: 2rem 0;
		}

		> * {
			position: relative;
			z-index: 100;
		}

		.bg-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateY(-25%);
			z-index: 1;

			.svg.icon {
				min-width: 100%;
				min-height: 200%;
			}
		}

		h2 {
			color: $color-blue;
			font-size: 3.5rem;
			font-weight: $weight-bold;
			margin: 0 0 4rem;

			@include media-breakpoint-down(sm) {
				font-size: 2.5rem;
				margin-bottom: 2rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}

		.event {
			a:not(.button) {
				&,
				&:link,
				&:visited {
					margin: 0 1rem 2rem;
					position: relative;
					display: block;
					overflow: hidden;
					min-height: 25rem;
					color: $color-black;
					background-color: $color-white;
					border-radius: 0.1875rem;
					box-shadow: 0 0 0 0 rgba($color-black, 0);

					@include media-breakpoint-down(xs) {
						margin: 0 0 1rem;
					}

					@include max( 576px ) {
						display: flex;
						flex-direction: column;
					}

				}

				&:hover {
					text-decoration: none;
					box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
					transform: scale(1.025);

					.photo {
						&:before {
							background-color: rgba($color-black, 0.5);
						}

						&:after {
							right: 1rem;
						}
					}
				}

				&:focus,
				&:active {
					text-decoration: none;
				}
			}

			.photo,
			.host,
			.title,
			.meta {
				:last-child {
					margin-bottom: 0;
				}
			}

			.photo {
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: rgba($color-black, 0);
					@include transition();
				}

				&:after {
					content: '';
					position: absolute;
					top: 1rem;
					right: -4rem;
					display: block;
					width: 3rem;
					height: 3rem;
					background: $color-yellow url('../images/icons/arrow-right-white.svg') center no-repeat;
					background-size: 1rem;
					border-radius: 50%;
					@include transition();
				}

				img {
					display: block;
					max-width: 100%;
					width: 100%;
					height: auto;
				}
			}

			.host,
			.date {
				display: block;
				color: $color-yellow;
				font-size: 0.875rem;
				font-style: italic;
				padding: 1rem 2rem 0;

				@include media-breakpoint-down(sm) {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				.icon {
					fill: rgba($color-yellow, 0.6);
					color: rgba($color-yellow, 0.6);
					margin-right: 0.5rem;
				}
			}

			.title {
				padding: 0.5rem 2rem;

				@include media-breakpoint-down(sm) {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				h3 {
					color: $color-black;
					font-size: 1.25rem;
					font-weight: $weight-bold;
				}
			}

			.meta {
				border-top: 1px solid rgba($color-black, 0.06);
				padding: 0.75rem 2rem;
				color: rgba($color-black, 0.6);
				font-size: 0.875rem;
				font-weight: $weight-bold;

				@include max( 576px ) {
					margin-top: auto;
				}

				@include media-breakpoint-up(sm) {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
				}

				@include media-breakpoint-down(md) {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				.icon {
					fill: rgba($color-black, 0.6);
					color: rgba($color-black, 0.6);
					margin-right: 0.5rem;
				}
			}

			&.view-all {
				a {
					&:link,
					&:visited {
						padding: 2rem;

						@include media-breakpoint-down(sm) {
							min-height: 25rem;
						}

						h3 {
							font-size: 1.5rem;
							margin-bottom: 2rem;
						}

						.button {
							position: absolute;
							left: 2rem;
							right: 2rem;
							bottom: 2rem;
							width: auto;
							text-align: center;
							font-size: 1rem;

							@include media-breakpoint-down(sm) {
								padding-left: 0;
								padding-right: 0;
								left: 1rem;
								right: 1rem;
								font-size: 0.875rem;
							}
						}

						@include media-breakpoint-down(sm) {
							padding: 1rem;
						}

						@include media-breakpoint-down(xs) {
							h3 {
								br {
									display: none;
								}
							}
						}
					}
				}
			}

			&:nth-child(1) {
				.photo {
					border-bottom: 0.5rem solid $color-blue;
				}
			}

			&:nth-child(2) {
				.photo {
					border-bottom: 0.5rem solid $color-lightblue;
				}
			}

			&:nth-child(3) {
				.photo {
					border-bottom: 0.5rem solid $color-yellow;
				}
			}

			&:nth-child(4) {
				.photo {
					border-bottom: 0.5rem solid $color-lightgreen;
				}
			}

			&:nth-child(5) {
				.photo {
					border-bottom: 0.5rem solid $color-green;
				}
			}
		}
	}
}
