.bx-wrapper {
	box-shadow: none;
	border: none;
	background: none;
	margin-bottom: 2rem;

	.bx-loading {
		background: none;
	}

	.bx-viewport {}

	.bx-controls {
		.bx-prev,
		.bx-next {
			color: transparent;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 3rem;
			height: 3rem;
			background: $color-white center no-repeat;
			background-size: 0.5rem;
			border-radius: 50%;
			box-shadow: 0 0.25rem 0.5rem 0.25rem rgba($color-darkblack, 0.08);
			cursor: pointer;
			opacity: 1;
		}

		.bx-prev {
			background-image: url('../images/icons/chevron-left.svg');
			left: 0;
			transform: translateX(-25%);

			&:hover {
				transform: translateX(-25%) scale(1.025);
			}

			@include media-breakpoint-up(sm) {
				transform: translateX(-50%);

				&:hover {
					transform: translateX(-50%) scale(1.025);
				}
			}
		}

		.bx-next {
			background-image: url('../images/icons/chevron-right.svg');
			right: 0;
			transform: translateX(25%);

			&:hover {
				transform: translateX(25%) scale(1.025);
			}

			@include media-breakpoint-up(sm) {
				transform: translateX(50%);

				&:hover {
					transform: translateX(50%) scale(1.025);
				}
			}
		}
	}
}
