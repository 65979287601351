.modal {
	&.video-modal {
		.modal-dialog {
			margin-top: 0;
			margin-bottom: 0;
			height: 100vh;

			.modal-content {
				background: none;
				border: none;
				height: 100vh;

				.embed-responsive {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}
