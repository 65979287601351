.page_contact-us {
	article.page {
		header {
			.header-image {
				display: none;
			}

			.page-title {
				text-align: center;
			}
		}
	}
}
