.button {
	display: inline-block;
	box-shadow: 0 0.35rem 0.65rem 0 rgba($color-black,0.10);
	border: 2px solid $color-blue;
	border-radius: 6.25rem;
	font-weight: $weight-extrabold;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0;
	padding: 0.5rem 1.5rem;
	color: $color-white;
	background-color: $color-blue;
	@include transition();

	:last-child {
		margin-bottom: 0;
	}

	&:hover {
		transform: translateY(-0.125rem);
		text-decoration: none;
		background-color: lighten($color-blue, 5%);
		border-color: lighten($color-blue, 5%);
	}

	&:active,
	&:focus {}

	&.block {
		display: block;
		width: 100%;
		max-width: 100%;

		& + & {
			margin-top: 1rem;
		}
	}

	&.rounded {
		border-radius: 0.1875rem;
	}

	&.square {
		border-radius: 0;
	}

	&.large {
		padding: 1.25rem 1.5rem;
		border-radius: 0.35rem;
		text-transform: none;

		&:hover {}

		&.no-hover {
			&:hover {
				transform: none;
			}

			&:active,
			&:focus {
				transform: none;
			}
		}

		h3 {
			font-weight: $weight-bold;
			font-size: 1.25rem;
			color: $color-white;
		}

		p {
			font-weight: $weight-medium;
			font-size: 1rem;
			color: $color-white;
			line-height: 1.25;
		}

		&.icon {
			position: relative;
			padding-left: 6.25rem;

			> .icon {
				position: absolute;
				top: 1.5rem;
				left: 1.5rem;
				width: 3.125rem;
				height: 3.125rem;
			}

			&:hover {}

			&:active,
			&:focus {
				transform: scale(1.025);
			}

			&.no-hover {
				&:hover {
					transform: none;
				}

				&:active,
				&:focus {
					transform: none;
				}
			}

			@include media-breakpoint-down(sm) {
				padding-left: 1rem;

				h3 {
					padding: 1rem 1rem 1rem 4rem;
				}
			}
		}

		&.blue {
			border-color: $color-blue;
		}

		&.lightblue {
			border-color: $color-lightblue;
		}

		&.darkblue {
			border-color: $color-darkblue;
		}

		&.yellow {
			border-color: $color-yellow;
		}

		&.purple {
			border-color: $color-purple;
		}
	}

	&.blue {
		color: $color-white;
		background-color: $color-blue;
		border-color: $color-blue;

		&:hover {
			background-color: lighten($color-blue, 5%);
			border-color: lighten($color-blue, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-white;
				background-color: $color-blue;
				border-color: $color-blue;
			}
		}
	}

	&.lightblue {
		color: $color-white;
		background-color: $color-lightblue;
		border-color: $color-lightblue;

		&:hover {
			background-color: lighten($color-lightblue, 5%);
			border-color: lighten($color-lightblue, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-white;
				background-color: $color-lightblue;
				border-color: $color-lightblue;
			}
		}
	}

	&.darkblue {
		color: $color-white;
		background-color: $color-darkblue;
		border-color: $color-darkblue;

		&:hover {
			background-color: lighten($color-darkblue, 5%);
			border-color: lighten($color-darkblue, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-white;
				background-color: $color-darkblue;
				border-color: $color-darkblue;
			}
		}
	}

	&.yellow {
		color: $color-blue;
		background-color: $color-yellow;
		border-color: $color-yellow;

		&:hover {
			background-color: lighten($color-yellow, 5%);
			border-color: lighten($color-yellow, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-blue;
				background-color: $color-yellow;
				border-color: $color-yellow;
			}
		}
	}

	&.purple {
		color: $color-white;
		background-color: $color-purple;
		border-color: $color-purple;

		&:hover {
			background-color: lighten($color-purple, 5%);
			border-color: lighten($color-purple, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-white;
				background-color: $color-purple;
				border-color: $color-purple;
			}
		}
	}

	&.white {
		color: $color-black;
		background-color: $color-white;
		border-color: $color-white;

		&:hover {
			color: $color-blue;
			border-color: $color-blue;
			background-color: $color-white;
		}

		&.no-hover {
			&:hover {
				color: $color-black;
				background-color: $color-white;
				border-color: $color-white;
			}
		}
	}

	&.lightgreen {
		color: $color-white;
		background-color: $color-lightgreen;
		border-color: $color-lightgreen;

		&:hover {
			background-color: lighten($color-lightgreen, 5%);
			border-color: lighten($color-lightgreen, 5%);
		}

		&.no-hover {
			&:hover {
				color: $color-white;
				background-color: $color-lightgreen;
				border-color: $color-lightgreen;
			}
		}
	}

	&.green {
	  color: $color-white;
	  background-color: $color-green;
	  border-color: $color-green;

	  &:hover {
	    background-color: lighten($color-green, 5%);
	    border-color: lighten($color-green, 5%);
	  }

	  &.no-hover {
	    &:hover {
	      color: $color-white;
	      background-color: $color-green;
	      border-color: $color-green;
	    }
	  }
	}

  &.lined {
    display: block;
    position: relative;
    background-color: #f0f1f5;
    color: $color-gray;
    border: none;
    font-size: 1.25rem;
    font-weight: $weight-bold;
    line-height: calc(24/20);
    box-shadow: none;
    border-radius: 0.3125rem;
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: 100%;
    align-items: center;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0.3125rem;
      background-color: $color-blue;
      @include transition();
      z-index: -1;
    }

    &:hover {
      color: $color-white;
      transform: translateY(0);

      &:before {
        width: 100%;
      }
    }

    &.green {
      &:before {
        background-color: $color-green;
      }
    }

    &.yellow {
      &:hover {
        color: $color-darkblue;
      }

      &:before {
        background-color: $color-yellow;
      }
    }

    &.lightblue {
      &:before {
        background-color: $color-lightblue;
      }
    }

    &.lightgreen {
      &:before {
        background-color: $color-lightgreen;
      }
    }

    &.purple {
      &:before {
        background-color: $color-purple;
      }
    }

    &.red {
      &:before {
        background-color: $color-red;
      }
    }
  }

	&.share {
		background-color: $color-white;
		border: 2px solid $color-black;
		border-radius: 2.5rem;

		&.facebook {
			color: $color-facebook;
			border-color: $color-facebook;
		}

		&.twitter {
			color: $color-twitter;
			border-color: $color-twitter;
		}

		&.email {
			color: $color-email;
			border-color: $color-email;
		}

		&.linkedin {
			color: $color-linkedin;
			border-color: $color-linkedin;
		}

		@include media-breakpoint-down(sm) {
			fill: $color-white;

			&.facebook {
				color: $color-white;
				border-color: $color-facebook;
				background-color: $color-facebook;
			}

			&.twitter {
				color: $color-white;
				border-color: $color-twitter;
				background-color: $color-twitter;
			}

			&.email {
				color: $color-white;
				border-color: $color-email;
				background-color: $color-email;
			}

			&.linkedin {
				color: $color-white;
				border-color: $color-linkedin;
				background-color: $color-linkedin;
			}
		}
	}
}
