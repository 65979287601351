
// -----------------------------------------------------------------------------
//! Utility nav
// -----------------------------------------------------------------------------

.utility .nav {
	margin-left: auto;

	li {
		line-height: 14px;
		margin-right: 23px;
	}

	li:last-of-type {
		position: relative;
		margin-right: 0;
	}

	a {
		font-size: 13px;
		line-height: 14px;
		font-weight: bold;
		color: $color-white;
		opacity: 0.7;
		-webkit-font-smoothing: antialiased;

		&:hover {
			opacity: 1;
		}

		&.button {
			opacity: 1;
			color: $color-blue;

			.icon {
				fill: $color-blue;
				width: 15px;
				height: 15px;
				vertical-align: text-top;
				margin-right: 9px;
				@include transition();
			}
		}
	}

	.has-button a {
		opacity: 1;
		color: #0054a6;
		display: inline-block;
		box-shadow: 0 0.35rem 0.65rem 0 rgba(35,31,32,.1);
		border: 2px solid #0054a6;
		border-radius: 6.25rem;
		font-weight: 700;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 0;
		padding: .5rem 1.5rem;
		transition: all .2s ease-in-out;
		background-color: #fdc82f;
		border-color: #fdc82f;

		&:hover {
		transform: translateY(-.125rem);
		}

		.icon {
			fill: $color-blue;
			width: 15px;
			height: 15px;
			vertical-align: text-top;
			margin-right: 9px;
			@include transition();
		}
	}
}

.mobile-utility-nav {
	padding: 15px 0;
	margin: 0;

	li {
		list-style-type: none;
	}

	a {
		display: block;
		padding: 12px 16px 12px 22px;
		font-size: 16px;
		line-height: 19px;
		font-weight: $weight-semibold;
		color: $color-black;

		&:hover {
			background: rgba( $color-lightgray, .05 );
			text-decoration: none;
		}

		.icon {
			display: none;
		}
	}

	@include min( $md ) {
		display: none;
	}
}


// -----------------------------------------------------------------------------
//! Primary Nav - <ul>
// -----------------------------------------------------------------------------

.primary-nav {
	padding: 0;
	margin: 0;

	@include min( $md ) {
		text-align: right;
	}
}

.sub-menu {
	padding: 0;

	@include min( $md ) {
		position: absolute;
		top: 100%;
		background-color: $color-white;
		border-radius: 0 0 3px 3px;
		opacity: 0;
		pointer-events: none;
		transition: .2s;
	}
}

li.hover .sub-menu {
	opacity: 1;
	pointer-events: auto;
}


// -----------------------------------------------------------------------------
//! Primary Nav - <li>
// -----------------------------------------------------------------------------

.primary-nav li {
	list-style-type: none;
}

.primary-nav > li {
	display: block;
	position: relative;
	border-left: 6px solid $color-darkblue;
	vertical-align: bottom;

	@include min( $md ) {
		display: inline-block;
		border: 0;

		~ li {
			margin-left: 20px;
		}

		&:hover > a {
			color: rgba( #0054A6, .8 );
		}

		&:last-of-type > a {
			padding-right: 0;
		}
	}

	&:nth-of-type(5n-3) {
		border-color: #00A3E0;
	}

	&:nth-of-type(5n-2) {
		border-color: #FDC82F;
	}

	&:nth-of-type(5n-1) {
		border-color: #6A3883;
	}

	&:nth-of-type(5n) {
		border-color: #A8AD00;
	}

	&:after {
		content: "";
		display: block;
		right: 0;
		left: 6px;
		bottom: 0;
		height: 1px;
		background: rgba( #231F20, .1 );

		@include min( $md ) {
			display: none;
		}
	}

	&:first-of-type:before {
		content: "";
		display: block;
		right: 0;
		left: 6px;
		top: 0;
		height: 1px;
		background: rgba( #231F20, .1 );

		@include min( $md ) {
			display: none;
		}
	}
}

.sub-menu li {
	@include min( $md ) {
		text-align: left;
		border-top: 2px solid rgba( $color-black, .2 );
	}
}


// -----------------------------------------------------------------------------
//! Primary nav - <a>
// -----------------------------------------------------------------------------

.primary-nav a {
	display: block;
	padding: 20px 16px 16px;
	font-size: 16px;
	line-height: 19px;
	font-weight: 800;
	text-transform: uppercase;
	color: $color-black;
	transition: color .2s;

	&:hover {
		text-decoration: none;
		background: rgba( $color-lightgray, .05 );
	}

	@include min( $md ) {
		padding: 45px 25px;
		font-size: 15px;
		color: rgba( $color-black, .8);

		&:hover {
			background: transparent;
		}
	}

	@include min( $lg ) {
		font-size: 16px;
	}
}

.primary-nav .menu-item-has-children a {
	@include max( $md ) {
		padding-top: 18px;
		padding-bottom: 13px;
	}
}

.primary-nav .sub-menu a {
	padding-top: 15px;
	padding-left: 26px;
	font-size: 14px;
	line-height: 19px;
	font-weight: $weight-semibold;
	color: $color-lightgray;

	@include min( $md ) {
		padding: 20px 25px;
		min-width: 230px;
		white-space: nowrap;
		color: #7B7979;
		font-weight: 600;
		font-size: 15px;
		-webkit-font-smoothing: antialiased;

		&:hover {
			color: $color-black;
			background: transparent;
		}
	}

	@include min( $lg ) {
		font-size: 16px;
	}
}


// -----------------------------------------------------------------------------
//! Current page
// -----------------------------------------------------------------------------

.primary-nav > li.current-menu-item > a,
.primary-nav > li.current-menu-parent > a {
	color: $color-darkblue;
}

// -----------------------------------------------------------------------------
//! Flare
// -----------------------------------------------------------------------------

@include min( $md ) {
	.primary-nav > li > a {
		position: relative;
		overflow: hidden;
	}

	.primary-nav > li > a:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 20px;
		bottom: 0;
		background: url(../images/logo-color.png);
		background-size: 111px;
		background-repeat: no-repeat;
		background-position: 0 4px;
		pointer-events: none;
		transition: .2s;

		width: 111px;
		height: 111px;
		top: 100%;
		left: 20px;
		transform: rotate(70deg);
	}

	.primary-nav > li:hover > a:after {
		margin-top: -22px;
		left: 0;
		transform: rotate(0deg);
	}
}


// -----------------------------------------------------------------------------
//! Mask
// -----------------------------------------------------------------------------

html {
	@include max( $md ) {
		overflow-x: hidden;
	}
}

body {
	@include max( $md ) {
		position: relative;
		overflow-x: hidden;

		&:after {
			content: '';
			display: none;
			background-color: transparent;
			@include transition();
		}

		&.nav-active {
			overflow: hidden;
			height: 100vh;

			&:after {
				content: '';
				display: block;
				position: fixed;
				top: 74px;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 40;
				background-color: rgba($color-black, 0.2);
			}
		}
	}

	// #wpadminbar {
	// 	top: -46px;
	// }
}
