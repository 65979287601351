// Grid system
.main {
	@include make-col-ready();
	@include media-breakpoint-up(sm) {
		@include make-col($main-sm-columns);

		.sidebar-primary & {
			@include make-col($main-sm-columns - $sidebar-sm-columns);
		}
	}
}

.sidebar {
	@include make-col-ready();
	@include media-breakpoint-up(sm) {
		@include make-col($sidebar-sm-columns);
	}
}
