@mixin transition($property: all, $duration: 0.15s, $easing: ease-in) {
	transition: $property $duration $easing;
}

@mixin min( $breakpoint ) {
	@media print, screen and ( min-width: $breakpoint ) {
		@content;
	}
}

@mixin max( $breakpoint ) {
	$breakpoint: $breakpoint - 1;
	@media screen and ( max-width: $breakpoint ) {
		@content;
	}
}
