.featured-downloads {
  background: $color-lightgray url('../images/featured-downloads-bg.jpg') center no-repeat;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 6rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include media-breakpoint-down(md) {
    padding-top: 3rem;
    padding-bottom: 4rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media all and (-webkit-min-device-pixel-ratio: 1.5) {
    background-image: url('../images/featured-downloads-bg@2x.jpg');
  }

  h3 {
    font-weight: $weight-bold;
    font-size: 2.375rem;
    color: $color-blue;
    margin-bottom: 2.5rem;
  }

  a {
    &,
    &:link,
    &:visited {
      position: relative;
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
      height: 100%;
      background: #e8f8Fe url('../images/fd-callout-bg.jpg') center no-repeat;
      background-size: cover;
      padding: 7rem 5rem 2.5rem 2.5rem;
      text-decoration: none;
      box-shadow: 0 0 0 0 rgba($color-black, 0);

      @media all and (-webkit-min-device-pixel-ratio: 1.5) {
        background-image: url('../images/fd-callout-bg@2x.jpg');
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.875rem;
        width: 2.8125rem;
        height: 2.8125rem;
        background: transparent url('../images/icons/arrow-right-blue.svg') center no-repeat;
        @include transition();
      }

      h4,
      p {
        color: $color-black;
        flex: 1 1 100%;
      }

      h4 {
        font-weight: $weight-extrabold;
        font-size: 1.75rem;
        line-height: calc(33/28);
      }

      p {
        margin-bottom: 0;
      }
    }

    &:hover {
      text-decoration: none;
      box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
      transform: scale(1.025);
    }
  }
}
