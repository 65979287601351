// -----------------------------------------------------------------------------
//! Home hero
// -----------------------------------------------------------------------------

.home .hero {
  position: relative;
  padding: 35px 0;
  background-image: linear-gradient(210deg, #0da8e2 0%, #0039a6 100%);

  @include min($lg) {
    padding: 48px 0;
  }

  .hero-text {
    text-align: center;
    max-width: 550px;
    margin: auto;
    -webkit-font-smoothing: antialised;

    @include min($lg) {
      margin: 0;
      text-align: left;
      max-width: 570px;
      width: 50%;
      padding-right: 50px;
    }
  }

  h1 {
    margin-bottom: 15px;
    font-weight: $weight-extrabold;
    font-size: 39px;
    line-height: 47px;
    color: #fff;

    @include min($lg) {
      margin-bottom: 26px;
      font-size: 56px;
      line-height: 66px;
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    color: #fff;
    font-weight: $weight-medium;

    @include min($lg) {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 34px;
    }
  }

  .hero-image {
    display: none;
    margin-top: 12px;
    height: 240px;
    max-width: 490px;
    margin-left: -20px;
    margin-right: -20px;
    background-image: url(../images/home-hero-mobile.png);
    background-repeat: no-repeat;
    background-size: 490px;
    background-position: center top;

    @include min($lg) {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      height: auto;
      width: 50%;
      max-width: 775px;
      margin: 0;
      background-image: url(../images/home-hero-desktop.png);
      background-size: cover;
      background-position: left;
    }
  }
}

// -----------------------------------------------------------------------------
//! Page Hero
// -----------------------------------------------------------------------------

.page-hero {
  min-height: 350px;
  padding: 44px 0;
  background: $color-purple url("../images/icons/fill-1x.svg") left 30%
    no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include min($md) {
    min-height: 400px;
    padding: 48px 0;
  }

  .contain {
    max-width: 780px;
  }

  h1 {
    margin: 0;
    font-size: 40px;
    line-height: 47px;
    font-weight: $weight-extrabold;
    color: #fff;

    @include min($md) {
      font-size: 56px;
      line-height: 60px;
    }
  }

  .hero-preamble {
    p {
      max-width: 650px;
      margin: 17px 0 0 0;
    }
  }

  p {
    max-width: 500px;
    margin: 17px auto 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
    font-weight: $weight-medium;
    color: #fff;

    @include min($md) {
      margin-top: 41px;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

// -----------------------------------------------------------------------------
//! Event Hero
// -----------------------------------------------------------------------------

.page-hero.event-hero {
  display: block;
  padding: 44px 0 0;
  position: relative;
  overflow: hidden;

  @include min($lg) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }

  .hero-text {
    position: relative;
    z-index: 15;
    text-align: left;
    padding: 0 20px;
    margin-bottom: 40px;

    @include min($lg) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      flex: 1;
      padding: 48px 40px 48px 32px;
      margin-bottom: 0;
    }
  }

  @include min(1244px) {
    .hero-text-inner {
      margin-left: calc((100vw - 1244px) / 2);
    }
  }

  .hero-image {
    display: block;
    max-height: 400px;

    @include min($lg) {
      flex: 1;
      min-height: 30em;
      max-width: 40em;

      image {
        clip-path: url(#mask-desktop);
      }
    }
  }
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .datetime,
  .city {
    font-weight: $weight-medium;
    font-size: 16px;
    line-height: 26px;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    padding-right: 25px;

    .icon {
      margin-right: 7px;
    }
  }
}
