
// -----------------------------------------------------------------------------
//! Resources Sidebar
// -----------------------------------------------------------------------------

.sidebar-category-list {

   position: relative;

   // mobile dropdown
   .mobile-btn-resource-list {

      box-shadow: 0 2px 4px 0 rgba(0,0,0, .10);
      padding: 3.0em 2.0em;
      color: #000;
      font-weight: $weight-extrabold;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
      padding: 20px 20px;
      position: relative;
      z-index: 3;
      background: #FFF;
      border-radius: 0;

      svg {

         position: absolute;
         right: 20px;
      }

      &.is-active {

         svg {

            transform: rotate(-180deg);
         }
      }
   }

   // sidebar title
   h3 { display: none; }

   // list of categories
   ul { 

      padding: 0; 
      margin: 0; 
      overflow: hidden;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 0;
      background: #FFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0, .10);
      transition: height 0.3s ease-out;

      li { list-style: none; }
   }

   button,
   a { 

      border: 0;
      border-bottom: 1px solid rgba($color-black, 0.10);
      text-decoration: none;
      display: block;
      cursor: pointer;
      outline: 0;
      background: transparent;
      margin: 0;
      padding: 20px 20px;
      color: #504C4D;
      font-weight: $weight-extrabold;
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
      transition: color 0.35s ease;


      &.is-active,
      &:hover {

         color: $color-blue;
         background: rgba(#0039A6, 0.05);
      }
   }

   ul li:first-of-type button,
   ul li:first-of-type a { padding-top: 30px; }

   // -----------------------------------------------------------------------------
   //! Resources Sidebar : Breakpoints
   // -----------------------------------------------------------------------------

   @include media-breakpoint-up(sm) {

      h3 {

         font-size: 16px;
         color: $color-black;
         font-weight: $weight-extrabold;
         padding-bottom: 17px;
         border-bottom: 2px solid rgba($color-black, 0.25);
      }
      
   }

   @include media-breakpoint-up(md) {

      padding-right: 40px;

      .mobile-btn-resource-list{ display: none; }

      h3 {

         font-size: 20px;
         display: block;
      }

      ul {

         height: auto !important;
         box-shadow: none;
         position: relative;
      }

      button,
      a { 

         font-size: 16px;
         border-bottom: 0;
         padding: 10px 0;

         &.is-active,
         &:hover {

            background: transparent;
         }
      }
   }
}

