.home {
	.learn-more {
		padding: 4rem 0;

		@include media-breakpoint-down(sm) {
			padding: 2rem 0;
		}

		h2 {
			margin-bottom: 2rem;
			font-size: 1.875rem;
			font-weight: $weight-bold;
			color: $color-blue;

			@include media-breakpoint-down(sm) {
				font-size: 1.5rem;
			}
		}

		p {
			font-size: 1.25rem;

			.button {
				margin-top: 1rem;

				@include media-breakpoint-down(sm) {
					margin-bottom: 1rem;
				}
			}
		}

		.button {
			p {
				font-size: 1rem;
			}
		}

		@include media-breakpoint-up(lg) {
			.what-is > * {
				margin-right: 1.25rem;
			}
		}
	}
}
