.page-content {
	section {
		&[class^="block"] + section[class^="block"] {
			&:not(.block-slider) {
				padding-top: 0;
			}
		}

		&[class^="block"] + section.block-slider {
			padding-top: 4rem;
		}

		&.block-slider {
			+ section.block-wysiwyg,
			+ section.block-form {
				padding-top: 4rem !important;
			}
		}
	}
}
