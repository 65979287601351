.page_resources {
	.archive-header {
		position: relative;
		overflow: hidden;
		padding: 6rem 0;
		color: $color-white;
		background: $color-purple url('../images/icons/fill-1x.svg') left 30% no-repeat;
		background-size: 100%;
		text-align: center;

		.archive-title {
			font-size: 3.5rem;
			font-weight: $weight-extrabold;
			margin-bottom: 2rem;
		}

		.archive-description {
			font-size: 1.125rem;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.browse-resources {
		background-color: $color-paleblue;
		padding: 4rem 0 2rem;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: 2rem 0 0;
		}

		> * {
			position: relative;
			z-index: 100;
		}

		.bg-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateY(-25%);
			z-index: 1;

			.svg.icon {
				min-width: 100%;
				min-height: 200%;
			}
		}

		h2 {
			color: $color-blue;
			font-size: 2.375rem;
			font-weight: $weight-bold;
			margin: 0 0 2rem;
		}

		.resource {
			margin-bottom: 2rem;

			a {
				&,
				&:link,
				&:visited {
					display: block;
					position: relative;
					overflow: hidden;
					box-shadow: 0 0 0 0 rgba($color-black, 0);
					padding: 1rem;
					background-color: $color-white;
					border-radius: 0.1875rem;

					&:after {
						content: '';
						position: absolute;
						top: 1rem;
						right: -4rem;
						display: block;
						width: 3rem;
						height: 3rem;
						background: $color-yellow url('../images/icons/arrow-right.svg') center no-repeat;
						background-size: 1rem;
						border-radius: 50%;
						@include transition();
					}

					.read-more {
						margin-bottom: 0;
					}
				}

				&:hover {
					text-decoration: none;
					box-shadow: 0 0.5rem 1.25rem 0.1875rem rgba($color-black, 0.3);
					transform: scale(1.025);

					&:after {
						right: 1rem;
					}
				}
			}
		}
	}
}
