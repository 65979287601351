.comment-list {
	@extend .list-unstyled;

	ol {
		list-style: none;
	}
}

.comment-form {
	p {
		@extend .form-group;
	}
	input[type="text"],
	input[type="email"],
	input[type="url"],
	textarea {
		@extend .form-control;
	}

	input[type="submit"] {
		@extend .btn;
		@extend .btn-secondary;
	}
}
