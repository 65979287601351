.join-acs-community {
  background-color: $color-lightgray;
  background-image: url('../images/featured-downloads-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 6rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include media-breakpoint-down(md) {
    padding-top: 3rem;
    padding-bottom: 4rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media all and (-webkit-min-device-pixel-ratio: 1.5) {
    background-image: url('../images/featured-downloads-bg@2x.jpg');
  }

  img {
    display: block;
  }

  h2 {
    font-weight: $weight-extrabold;
    font-size: 1.75rem;
    color: $color-black;
    margin-bottom: 1rem;
  }

  p {
    font-weight: $weight-medium;
    font-size: 1.125rem;
    line-height: calc(30/18);
    margin-bottom: 1.25rem;
  }

  .acs-member {
    border-radius: 2rem;

    h3 {
      font-weight: $weight-extrabold;
      color: $color-blue;
    }
  }
}
