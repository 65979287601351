.block-wysiwyg {
  padding: 4rem 0;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 2rem 0;
  }

  &.preamble {
    padding: 1rem 2rem 2rem;

    @include media-breakpoint-down(md) {
      padding: 2rem 0;
    }

    p {
      margin-bottom: 0;
      font-size: 1.25rem;
      color: $color-gray;
    }
  }
}
