main,
.main {
	padding-left: 0;
	padding-right: 0;
}

.container {
	@include media-breakpoint-only(xl) {
		width: 1170px;
	}

	@include media-breakpoint-only(lg) {
		width: 1170px;
	}

	@include media-breakpoint-only(md) {
		width: 1170px;
	}

	@include media-breakpoint-up(md) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
