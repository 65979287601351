.block-form {
	padding: 4rem 0;
	position: relative;
	// overflow: hidden;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.container {
		margin-top: 0.5rem;
	}
}
