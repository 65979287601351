.page {
	article.page {
		header {
			position: relative;
			overflow: hidden;
			padding: 6rem 0;
			color: $color-white;
			background: $color-purple url('../images/icons/fill-1x.svg') left 30% no-repeat;
			background-size: 100%;

			.header-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: (100% / 3);

				.svg.icon {
					position: relative;
					width: 100%;
					height: 100%;

					@include media-breakpoint-up(sm) {
						svg:not(:root) {
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							height: 100%;
						}
					}

					image {
						clip-path: url(#mask-desktop);
					}
				}
			}

			@include media-breakpoint-down(xs) {
				background: $color-purple;
				padding: 2rem 1rem 12rem;

				.header-image {
					top: auto;
					left: 0;
					right: 0;
					bottom: 0;
					width: auto;
					height: 10rem;

					.svg.icon {
						svg:not(:root) {
							transform: translateY(-10%);
						}

						#mask-mobile {
							transform: translate(3%, 2.5%);
						}

						image {
							clip-path: url(#mask-mobile);
						}
					}
				}
			}

			.page-title {
				font-size: 3.5rem;
				font-weight: $weight-extrabold;

				@include media-breakpoint-down(md) {
					font-size: 3rem;
				}

				@include media-breakpoint-down(sm) {
					font-size: 2.5rem;
				}
			}
		}

		.page-content {
			:last-child {
				margin-bottom: 0;
			}

			h2,
			h3,
			h4 {
				font-weight: $weight-extrabold;
				color: $color-darkblack;
				margin-bottom: 1.5rem;
			}

			h2 {
				font-size: 2.125rem;
				margin-bottom: 2rem;
			}

			h3 {
				font-size: 1.5rem;
			}

			h4 {
				font-size: 1.125rem;
				font-weight: $weight-bold;
			}

			p {
				a {
					&,
					&:link,
					&:visited {
						color: $color-black;
						text-decoration: none;
						border-bottom: 2px solid rgba($color-black, 0.4);
					}

					&:hover {
						text-decoration: none;
						border-bottom-color: $color-black;
					}

					&:focus,
					&:active {}
				}

				a.only-child {
					&,
					&:link,
					&:visited {
						font-weight: $weight-bold;
						color: $color-purple;
						border-bottom: none;

						&:after {
							content: '';
							display: inline-block;
							margin-left: 0.5rem;
							width: 1rem;
							height: 1rem;
							background: transparent url('../images/icons/arrow-right-purple.svg') center no-repeat;
							background-size: 100% auto;
						}
					}

					&:hover {}

					&:focus,
					&:active {}
				}
			}

			p,
			blockquote,
			ol,
			ul,
			table {
				margin-bottom: 2rem;
			}

			ol,
			ul {
				li {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			ol {
				counter-reset: ol-item-1;
				list-style: none;
				padding-left: 1.5rem;

				li {
					counter-increment: ol-item-1;
					position: relative;

					&:before {
						content: counter(ol-item-1, decimal);
						position: absolute;
						top: 0rem;
						left: -1.5rem;
						width: 1rem;
						line-height: 1;
						font-size: 1.125rem;
						font-weight: $weight-semibold;
						color: $color-blue;
					}
				}

				ol {
					counter-reset: ol-item-2;
					margin-top: 1rem;

					li {
						counter-increment: ol-item-2;

						&:before {
							content: counter(ol-item-2, lower-alpha);
						}
					}

					ol {
						counter-reset: ol-item-3;

						li {
							counter-increment: ol-item-3;

							&:before {
								content: counter(ol-item-3, decimal);
							}
						}
					}
				}
			}

			ul {
				list-style: none;
				padding-left: 1.5rem;

				li {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: 0.5rem;
						left: -1.5rem;
						width: 0.35rem;
						height: 0.35rem;
						background: transparent url('../images/icons/bullet.svg') center no-repeat;
						background-size: 100% 100%;
					}
				}

				ul {
					margin-top: 1rem;

					li {
						&:before {
							top: 0.2rem;
							width: 1rem;
							height: 1rem;
							background-image: url('../images/icons/dash.svg');
						}
					}

					ul {
						li {
							&:before {
								top: 0.5rem;
								width: 0.35rem;
								height: 0.35rem;
								background-image: url('../images/icons/bullet.svg');
							}
						}
					}
				}
			}

			img {
				max-width: 100%;
				height: auto;
			}

			figure {
				margin: 0 auto 2rem;

				a {
					&,
					&:link,
					&:visited {
						position: relative;
						display: block;

						&:after {
							content: '';
							position: absolute;
							top: 1rem;
							right: 1rem;
							display: block;
							width: 3rem;
							height: 3rem;
							background: $color-yellow url('../images/icons/expand.svg') center no-repeat;
							background-size: 1rem;
							border-radius: 50%;
							@include transition();
						}
					}
				}

				&.wp-caption {
					figcaption {
						font-size: 0.875rem;
						text-align: center;
						padding-top: 0.875rem;
						color: rgba($color-black, 0.4);
					}
				}
			}

			.the-content {
				padding: 4rem 0;
				position: relative;
			}
		}
	}
}
