.archive-cats-resource {
  .featured-resources {
    @include min($md) {
      > .container {
        > .row {
          justify-content: space-between;

          .featured-resource {
            flex-basis: 49%;
          }

          .other-featured-resources {
            flex-basis: auto;
          }
        }
      }
    }

    .cat-resource {
      position: relative;
      margin-bottom: 2.75rem;
      @include transition();

      &:hover {
        transform: scale(1.025);
      }

      &:last-child {
        margin-bottom: 0;
      }

      .resource-image {
        display: block;
        float: left;
        border-bottom: 6px solid $color-black;
        max-width: 15rem;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .resource-copy {
        margin-left: 16.5rem;

        h4 {
          font-weight: $weight-bold;
          font-size: 1.25rem;
          color: $color-black;

          a {
            color: $color-black;
          }
        }
      }

      .resource-type {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 0.625rem;
        left: 0.9375rem;
        transform: translateX(-50%);

        @include max($sm) {
          background-size: cover;
          top: 1rem;
          left: 1rem;
        }

        // &.link {}
      }
    }

    @each $name, $color in $colors {
      &.has-color-#{$name} {
        .card {
          .photo {
            border-bottom-color: $color !important;
          }
        }

        .resource-image {
          border-bottom-color: $color !important;
        }
      }
    }
  }

  .join-acs-community {
    background-image: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-white, 0.2);
      background-image: url('../images/icons/fill-4x.svg');
      background-position: center;
      background-repeat: no-repeat;
    }

    h2,
    p {
      color: $color-white;
    }
  }

  .more-cat-resources {
    padding-top: 5.75rem;
    padding-bottom: 2.875rem;

    h2 {
      font-weight: $weight-extrabold;
      font-size: 2.125rem;
      color: $color-black;
      margin-bottom: 2.75rem;
    }

    .cat-resource {
      position: relative;
      margin-bottom: 2.75rem;
      @include transition();

      &:hover {
        transform: scale(1.025);
      }

      &:last-child {
        margin-bottom: 0;
      }

      .resource-image {
        display: block;
        float: left;
        border-bottom: 6px solid $color-black;
        max-width: 23.75rem;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .resource-copy {
        margin-left: 26.875rem;

        h3 {
          font-weight: $weight-bold;
          font-size: 1.25rem;
          color: $color-black;

          a {
            color: $color-black;
          }
        }
      }

      .resource-type {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 0.625rem;
        left: 0.9375rem;
        transform: translateX(-50%);

        @include max($sm) {
          background-size: cover;
          top: 1rem;
          left: 1rem;
        }

        // &.link {}
      }
    }

    @each $name, $color in $colors {
      &.has-color-#{$name} {
        h2 {
          color: $color !important;
        }

        .cat-resource {
          .resource-image {
            border-bottom-color: $color !important;
          }
        }
      }
    }
  }

  .more-acs-resources {
    padding-top: 2.875rem;
    padding-bottom: 5.75rem;

    h2 {
      font-weight: $weight-extrabold;
      font-size: 2.125rem;
      color: $color-purple;
      margin-bottom: 2.75rem;
      text-align: center;
    }

    // .col-6 {
    //   margin-bottom: 1.875rem;

    //   @include media-breakpoint-up(md) {
    //     margin-bottom: 0.625rem;
    //   }
    // }

    .button {
      display: flex;
      min-width: 100%;

      // @include media-breakpoint-up(md) {
      //   display: inline-flex;
      //   min-width: 20.3125rem;
      // }
    }
  }
}
