.home,
.page_press {
	.twitter-feed {
		position: relative;
		background-image: linear-gradient(-225deg, $color-lightblue 0%, $color-darkblue 100%);
		text-align: center;
		color: $color-white;
		padding: 4rem 0;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			padding: 2rem 0;
		}

		> * {
			position: relative;
			z-index: 100;
		}

		.bg-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateY(-25%);
			z-index: 1;

			.svg.icon {
				min-width: 100%;
				min-height: 200%;
			}
		}

		.twitter {
			.svg.icon {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		.text {
			margin-top: 2rem;
			font-size: 1.25rem;
			font-weight: $weight-semibold;

			a {
				&,
				&:link,
				&:visited {
					color: $color-white;
					white-space: nowrap;
					position: relative;

					&:after {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						bottom: 1px;
						height: 2px;
						background-color: rgba($color-white, 0.45);
						@include transition();
					}
				}

				&:hover {
					text-decoration: none;
					color: $color-white;

					&:after {
						background-color: rgba($color-white, 0.75);
					}
				}

				&:focus,
				&:active {
					text-decoration: none;
					color: $color-white;

					&:after {
						background-color: rgba($color-white, 0.9);
					}
				}
			}
		}

		.meta {
			margin-top: 2rem;
			font-size: 0.75rem;
			font-weight: $weight-bold;

			a {
				&,
				&:link,
				&:visited {
					color: $color-white;
				}

				&:hover {
					text-decoration: none;
					color: $color-white;
				}

				&:focus,
				&:active {
					text-decoration: none;
					color: $color-white;
				}
			}
		}
	}
}
