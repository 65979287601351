body {
	font-family: $font-family;
	color: $color-black;
	background: $color-white url('../images/tile.png') center;
	background-attachment: fixed;
	-webkit-font-smoothing: antialiased;
	min-width: 320px;
}

.contain {
	max-width: 77.75rem;
	margin: auto;
	padding-left: 1.25rem;
	padding-right: 1.25rem;

	@include min( $md ) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&.narrow {
		max-width: 66.5rem;
	}
}

.wrap {
	z-index: 10;
	position: relative;
}
