footer.content-info {
  position: relative;
  z-index: 11;
  background-color: $color-white;
  box-shadow: 0 -0.5rem 2rem 0 rgba($color-black, 0.05);

  .upper {
    //border-top: 1px solid rgba($color-black, 0.1);
    color: $color-black;
    font-size: 1.125rem;

    [class^="col-"] {
      padding: 2rem 0;

      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }
    }

    img {
      width: 200px;

      @include media-breakpoint-up(sm) {
        width: 100%;
        padding: 0 0.5rem;
      }
    }
  }

  .lower {
    border-top: 1px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.55);
    font-size: 0.75rem;
    font-weight: $weight-bold;
    text-transform: uppercase;

    [class^="col-"] {
      padding: 1rem 0;

      @include media-breakpoint-up(sm) {
        &:nth-child(1) {
          padding-right: 1rem;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            top: 1rem;
            right: 0;
            bottom: 1rem;
            width: 1px;
            background-color: rgba($color-black, 0.1);
          }
        }

        &:nth-child(2) {
          padding-left: 1rem;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem;

      [class^="col-"] {
        padding: 0.5rem 0;
      }
    }
  }
}
