.block-slider {
	background-color: $color-paleblue;
	padding: 4rem 0;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		padding-top: 2rem !important;
	}

	> * {
		position: relative;
		z-index: 100;
	}

	.bg-icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateY(-25%);
		z-index: 1;

		.svg.icon {
			min-width: 100%;
			min-height: 200%;
			fill: $color-darkgray;
		}
	}

	h2 {
		color: $color-blue;
		font-size: 1.5rem;
		font-weight: $weight-extrabold;
		margin: 0 0 2rem;
	}

	.bx-wrapper {
		margin-bottom: 0;

		.bx-controls {
			.bx-prev,
			.bx-next {
				top: 25%;
			}
		}
	}

	.bxslider {
		.slide {
			:last-child {
				margin-bottom: 0;
			}

			&:before {
				display: none;
			}

			a {
				&,
				&:link,
				&:visited {
					padding: 0;
					display: block;
					color: $color-black !important;
					text-decoration: none !important;

					@include media-breakpoint-up(sm) {
						padding: 0.875rem;
					}
				}

				&:hover {
					text-decoration: none;
					transform: scale(1.025);

					h4 {
						// border-bottom-color: transparent;
					}
				}

				&:active,
				&:focus {
					outline: none;
				}
			}

			.image {
				margin-bottom: 2rem;

				&:empty {
					margin-bottom: 0;
				}

				img {
					margin-left: auto;
					margin-right: auto;
				}

				.svg.icon {
					display: none;
				}
			}

			&.video {
				.image {
					position: relative;

					.svg.icon {
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						width: 3.5rem;
						height: 3.5rem;

						circle {
							stroke: $color-white;
							stroke-opacity: 0.3;
							stroke-width: 0.375rem;
							fill: transparent;
							@include transition();
						}

						path {
							fill: $color-white;
						}
					}
				}

				&:hover {
					.image {
						.svg.icon {
							circle {
								stroke-opacity: 1;
							}
						}
					}
				}
			}

			h4 {
				display: inline-block;
				font-size: 1.25rem;
				font-weight: $weight-bold;
				color: rgba($color-black, 0.9);
				// border-bottom: 1px solid rgba($color-black, 0.9);
				@include transition();
			}

			p {
				color: $color-gray;
				font-size: 0.875rem;
			}
		}
	}

}
