.home {
	.featured-speakers {
		padding: 4rem 0;

		@include media-breakpoint-down(sm) {
			padding: 2rem 0;
		}

		h2 {
			color: $color-blue;
			font-size: 3.5rem;
			font-weight: $weight-bold;
			margin: 0 0 4rem;

			@include media-breakpoint-down(sm) {
				font-size: 2.5rem;
				margin-bottom: 2rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}

		.speaker {
			text-align: center;

			@include media-breakpoint-down(sm) {
				margin-top: 1rem;
			}

			.wrap {
				position: relative;
				display: block;
				overflow: hidden;
				min-height: 100%;
				color: $color-black;
				background-color: $color-white;
				border: 1px solid rgba($color-black, 0.1);
				border-radius: 0.1875rem;
				margin: 0.25rem;

				@include media-breakpoint-down(sm) {
					padding: 1rem;
					margin: 0;
				}

				.photo,
				.name,
				.bio {
					:last-child {
						margin-bottom: 0;
					}
				}

				.photo {
					padding: 2rem 2rem 0.5rem;

					img {
						border-radius: 50%;
					}

					@include media-breakpoint-down(sm) {
						padding: 0;
						max-width: 6.25rem;
						float: left;

						img {
							border-radius: 0;
						}
					}
				}

				.name {
					padding: 0.5rem;

					h3 {
						font-size: 1.5rem;
						font-weight: $weight-bold;
					}

					@include media-breakpoint-down(sm) {
						padding: 0;
						text-align: left;
						margin-left: 7.5rem;

						h3 {
							margin-bottom: 0.5rem;
						}
					}
				}

				.title {
					padding: 0.5rem;

					@include media-breakpoint-down(sm) {
						padding: 0;
						text-align: left;
						margin-left: 7.5rem;
					}
				}

				.bio {
					padding: 0.5rem 2rem 2rem;
					font-size: 0.875rem;
					font-style: italic;

					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
			}

			@include media-breakpoint-up(md) {
				&:nth-child(3n+1) {
					.wrap {
						border-top: 0.5rem solid $color-lightblue;
					}
				}

				&:nth-child(3n+2) {
					.wrap {
						border-top: 0.5rem solid $color-yellow;
					}
				}

				&:nth-child(3n+3) {
					.wrap {
						border-top: 0.5rem solid $color-lightgreen;
					}
				}
			}
		}
	}
}
