.resources {
	background: $color-white;

	.container-resources {
		width: auto;
		max-width: 1300px;
		padding: 0;

		.acs-member {
			margin-top: 1.5rem;
		}
	}

  .resource-jump-links {
    margin-top: 3.75rem;
    margin-bottom: 5rem;

    h3 {
      font-weight: $weight-bold;
      font-size: 1.25rem;
      color: $color-black;
      margin-bottom: 1.5rem;
    }
  }

  .resource-category {
    + .resource-category {
      margin-top: 7.75rem;
    }

    h2 {
      font-weight: $weight-extrabold;
      font-size: 2.125rem;
      margin-bottom: 1rem;
    }

    .category-description {
      p {
        font-weight: $weight-medium;
        font-size: 1.125rem;
        line-height: calc(25/18);
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    .more-resources {
      font-size: 1rem;
      color: $color-purple;
      font-weight: $weight-bold;

      &:after {
        content: '';
        display: inline-block;
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        background: transparent url('../images/icons/arrow-right-purple.svg') center no-repeat;
        background-size: 100% auto;
      }
    }

    .resource-cards {
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        margin-top: 3rem;
      }

      .card {
        @include media-breakpoint-up(lg) {
          a {
            margin-bottom: 0;
          }
        }

        .title {
          h3 {
            line-height: calc(24/20);
          }
        }
      }

      @each $name, $color in $colors {
        &.category-color-#{$name} {
          .card {
            .photo {
              border-bottom-color: $color !important;
            }
          }
        }
      }
    }
  }

	.block-big-section {
		padding: 2rem 1.25rem;

		.button.large {
			h3 {
				color: $color-blue;
			}
		}
	}

	.container-cards {
		padding: 2em;
		position: relative;

		.row {
			opacity: 1;
			transition: opacity 0.45s ease-out;

			&.is-anim {
				opacity: 0;
			}
		}
	}

	// Modifiers
	*.is-loading {
		pointer-events: none;

		&::before {
			content: '';
			background: url('../images/preloader.png') no-repeat;
			background-size: contain;
			position: absolute;
			width: 50px;
			height: 50px;
			z-index: 9;
			transform-origin: center center;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			animation-duration: 0.4s;
			animation-name: loaderRotation;
			animation-iteration-count: infinite;
			animation-timing-function: linear;

			@keyframes loaderRotation {
				to {
					transform: translate(-50%, -50%) rotate(360deg);
				}
			}
		}
	}
	// -----------------------------------------------------------------------------
	//! Resources Sidebar : Breakpoints
	// -----------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		.block-big-section {
			padding: 2rem 1rem;
		}

		.container-resources {
			padding-right: 15px;
			padding-left: 15px;
		}

		.container-cards {
			padding: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.block-big-section {
			padding: 4rem 2rem;
		}
	}
}
